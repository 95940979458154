/* ©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
 */
import { PolymerElement, html } from "@polymer/polymer/polymer-element.js";
import "./mdl-grid-css.js";
/* import '../bower_components/swiper/dist/css/swiper.css'; */
/*  shared styles for all elements and index.html  */

const AppStyles = document.createElement("dom-module");

AppStyles.innerHTML = `<template>
        <style include="mdl-grid-css iron-flex iron-flex-factors iron-flex-alignment">

            /* hidden attribute inside Shadow DOM doesn't work without this style being availabe in each custom element*/
            [hidden] {
                display: none!important;
            }

            /*Removed from mdl-grid.html*/
            /*the root container - no space on mobile except bottom margin, normal grod spacing on tab and desktop*/
            .mdl-grid.mdl-grid--root {
                padding: 8px;
            }

            .mdl-grid--root>.mdl-cell{
                margin: 8px
            }

            .spinner-innder-div{
                margin: 10%;
            }

            .mdl-grid.mdl-grid--center-justified {
                justify-content: center;
            }
            
            .mdl-cell--auto-width {
                box-sizing: border-box;
                width: auto;
            }
			app-dialog[name="h_Authorization"] {
                --dialog-background-color: {
                    background-color: var(--brand-color);
            };
                --dialog-text-color: {
                color: var(--background-primary);
              }
			}
			.chatbot-body-app-chatbot {
            height: calc(100% - 125px);
            width: 100%;
            padding-top: 0 !important;
            overflow-y: scroll;
            overflow-x: hidden;
            background-image: var(--texture-image);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            }
            
            .mdl-right-no-spacing {
                padding-right: 0 !important;
                width: calc(100% - 8px) !important;
            }
			
			.money-bag-fin-amount{
			  background-image: var(--ic_money_bag_normal_images);
			  background-repeat: no-repeat;
			  background-size: 3em;
			  background-position-x: 0.5em;
			  width: 4em;
			  height: 2em;
			  text-align: center;
			  padding-top: 1.5em;
			}
                
            .mdl-right-no-spacing > .mdl-cell {
                margin-right: 0 !important;
                width: calc(100% - 8px) !important;
            }
            
            :dir(rtl) .mdl-right-no-spacing {
                padding-left: 0 !important;
                width: calc(100% - 8px) !important;
            }
                
            :dir(rtl) .mdl-right-no-spacing > .mdl-cell {
                margin-left: 0 !important;
                width: calc(100% - 8px) !important;
            }

            @media (max-width: 479px) {
                .mdl-right-no-spacing-phone {
                    padding-right: 0 !important;
                    width: calc(100% - 8px) !important;
                }
                
                .mdl-right-no-spacing-phone > .mdl-cell {
                    margin-right: 0 !important;
                    width: calc(100% - 8px) !important;
                }
                
                :dir(rtl) .mdl-right-no-spacing-phone {
                    padding-left: 0 !important;
                    width: calc(100% - 8px) !important;
                }
                
                :dir(rtl) .mdl-right-no-spacing-phone > .mdl-cell {
                    margin-left: 0 !important;
                    width: calc(100% - 8px) !important;
                }
                
                /*the root container - no space on mobile except bottom margin, normal grod spacing on tab and desktop*/
                .mdl-grid.mdl-grid--root {
                    padding: 0;
                }
                .mdl-grid--root>.mdl-cell {
                    margin: 0 0 8px 0;
                    width: 100%;
                }
                .fade-in{
                    animation: fadeIn ease 4s;
                   -webkit-animation: fadeIn ease 4s;
                   -moz-animation: fadeIn ease 4s;
                   -o-animation: fadeIn ease 4s;
                   -ms-animation: fadeIn ease 4s;
                 }
                 .fade-out{
                   animation: fadeOut 3s .2s ease-in forwards;
                   -webkit-animation: fadeOut 3s .2s ease-in forwards;
                   -moz-animation: fadeOut 3s .2s ease-in forwards;
                   -o-animation: fadeOut 3s .2s ease-in forwards;
                   -ms-animation: fadeOut 3s .2s ease-in forwards;
                 }
                 @keyframes fadeOut{
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-moz-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-webkit-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-o-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 }
                 
                 @-ms-keyframes fadeOut {
                   0%   { opacity: 1; }
                   30%  { opacity: 1; }
                   50%  { opacity: 1; }
                   70%  { opacity: 0.2; }
                   100% { opacity: 0.2; }
                 } 
                 @keyframes fadeIn{
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 @-moz-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 
                 @-webkit-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 
                 @-o-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 
                 @-ms-keyframes fadeIn {
                   0% { opacity:0; }
                   100% { opacity:1;}
                 }
                 .p-fade-in{
                    animation: pfadeIn ease 1s;
                   -webkit-animation: pfadeIn ease 1s;
                   -moz-animation: pfadeIn ease 1s;
                   -o-animation: pfadeIn ease 1s;
                   -ms-animation: pfadeIn ease 1s;
                 }
                 .p-fade-out{
                   animation: pfadeOut 1s .2s ease-in forwards;
                   -webkit-animation: pfadeOut 1s .2s ease-in forwards;
                   -moz-animation: pfadeOut 1s .2s ease-in forwards;
                   -o-animation: pfadeOut 1s .2s ease-in forwards;
                   -ms-animation: pfadeOut 1s .2s ease-in forwards;
                 }
                 @keyframes pfadeOut{
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-moz-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-webkit-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-o-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 }
                 
                 @-ms-keyframes pfadeOut {
                    0%   { opacity: 1; }
                    100% { opacity: 0; }
                 } 
                 @keyframes pfadeIn{
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 @-moz-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 
                 @-webkit-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 
                 @-o-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 
                 @-ms-keyframes pfadeIn {
                    0%   { opacity: 0; }
                    100% { opacity: 1; }
                 }
                 .animate-left{ position: relative; animation: animateleft 0.8s; @apply --animate-left-value; }
                 @keyframes animateleft{ from{ right: 0; opacity: 0 } to{ right: -100%; opacity: 1 } }
                 .animate-right{ position: relative; animation: animateright 0.8s;
                 @apply --animate-right-value; } 
                 @keyframes animateright{ from{ right: -100%; opacity: 0 } to{ right: 0; opacity: 1 } }
                 .opacity-screen{
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--card-light-background);
                    display:none;
                    z-index:1;
                  }
                  .opacity-screen.show {
                    display: block;
                  }
                  .success-card {
                    background: none;
                    box-shadow: none;
                  }
                  .payment-details{
                    margin-top: 20px;
                    margin-bottom:4px;
                    padding: 16px 0px;
                    border-top: 1px solid var(--secondary-dark-color);
                    border-bottom: 1px solid var(--secondary-dark-color);
                  }          
                  .download-icon{
                    position: relative;
                    left: 48px;
                    top: 2px;
                  }
                  .share-icon {
                    position: relative;
                    right: 48px;
                    top: 1px;
                  }
            }
            /*Removed from mdl-grid.html*/
            
            #index_HeaderPanel {
                --paper-scroll-header-panel-header-container: {
                    z-index: 2;
                }
                --paper-scroll-header-panel-container: {
                    -webkit-overflow-scrolling: auto;
                }
            }
            #index_HeaderPanel[phone],#index_HeaderPanel[tablet] {
             
                --paper-scroll-header-panel-container: {
                    -webkit-overflow-scrolling: touch;
                }
            }
            
            ::-webkit-scrollbar {
                width: 17px;
            }
            /* Customize Thumb */
            
            ::-webkit-scrollbar-thumb {
                background: var(--primary-dark-color);
                border-radius: 10px;
                border: 6px solid transparent;
                background-clip: content-box;
            }
            /* Customize Track */
            
            ::-webkit-scrollbar-track {
                border: 8px solid transparent;
                background: var(--divider-color);
                background-clip: content-box;
            }
            /*Css for One Gini*/
            
            .biometric-round-button {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: 0 auto;
            }
            
            .biometric-round-button > iron-icon {
                --iron-icon-width: 31px;
                --iron-icon-height: 31px;
                --iron-icon-fill-color: var(--primary-light-colo);
            }
            
            .biometric-iron-icon {
                width: 31px;
                height: 31px;
                border-radius: 50%;
                border: 1;
                margin: 0 auto;
            }
            
            .biometric-iron-icon-process {
                background: var(--biometric-bg-color);
                width: 10em;
                height: 10em;
                border-radius: 50%;
                border: 1;
                margin: 0 auto;
            }
            
            .biometric-icon-border {
                border: 1px solid var(--divider-color);
                padding: 4em;
            }
            
            .biometric-icon-border-process {
                border: 8px solid var(--secondary-light-color);
                padding: 4em;
            }
            /*Css for One Gini*/
            /*Dynamic tabs add, delete*/
            
            .menu-tabs-3 {
                background-color: transparent;
                color: var(--disabled-dark-color);
                min-width: 165px;
                text-align: center;
                cursor: pointer;
                border: 1px solid var(--outline-divider-color);
                border-right: none;
                border-bottom: none;
                border-radius: 4px 0 0 0;
                min-height: 30px;
                transform: scale(.91, .9) translate(0px, 3px);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                transition: transform 0.1s ease;
                font-family: var(--medium-font);
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }
            
            .menu-tabs-3:after {
                content: "";
                position: absolute;
                border: 1px solid var(--outline-divider-color);
                right: -10px;
                top: -1px;
                bottom: 0;
                width: 16px;
                border-left: none;
                border-bottom: none;
                /*background: wheat;*/
                border-radius: 0 4px 0 0;
                transform: skewx(19deg);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }
            
            :dir(rtl) .menu-tabs-3 {
                background-color: transparent;
                color: var(--disabled-dark-color);
                min-width: 165px;
                text-align: center;
                cursor: pointer;
                border: 1px solid var(--outline-divider-color);
                border-left: none;
                border-bottom: none;
                border-radius: 4px 0 4px 0;
                min-height: 30px;
                transform: scale(.91, .9) translate(0px, 3px);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                transition: transform 0.1s ease;
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }
            
            :dir(rtl) .menu-tabs-3:after {
                content: "";
                position: absolute;
                border: 1px solid var(--outline-divider-color);
                left: -10px;
                right: auto;
                top: -1px;
                bottom: 0;
                width: 16px;
                border-right: none;
                border-bottom: none;
                /*background: wheat;*/
                border-radius: 4px 0 0 0;
                transform: skewx(-19deg);
                background-image: linear-gradient(-180deg, var(--tab-gradient-start-color) 0%, var(--tab-gradient-end-color) 100%);
                font-family: SF Pro Display;
                /*box-shadow: 0 -1px 0 0 #DCDCDC, 1px 0 0 0 #DCDCDC, -1px 0 0 0 #DCDCDC, inset 0 -1px 0 0 #DCDCDC;*/
				padding:3px 0;
            }
            
            .menu-tabs-3-active,
            :dir(rtl) .menu-tabs-3-active{
                bottom: 0;
                background: var(--brand-color-100,#003e4d);
                font-family: var(--medium-font);
               /* z-index: 1; */
                color: var(--primary-light-color);
            }
            
            .menu-tabs-3-active.menu-tabs-3:after,
            :dir(rtl) .menu-tabs-3-active.menu-tabs-3:after{
                background: var(--brand-color-100,#003e4d);
            }

            .menu-tabs-3 .iron-icon-style-s,
            :dir(rtl) .menu-tabs-3 .iron-icon-style-s{
                color: var(--disabled-text-color);
                opacity: 70%;
            }
            
            .menu-tabs-3-active .iron-icon-style-s,
            :dir(rtl) .menu-tabs-3-active .iron-icon-style-s{
                color: var(--primary-light-color);
                opacity: 70%;
            }
            
            /*removing focus outline for tab heading*/
            .menu-tabs-3 .style-tab:focus {
                outline: none;
            }
            
            /*.tab-container{
                min-width: 96px;
                margin-bottom: 0px !important;
            }*/
            /*Dynamic tabs add, delete*/
            
            paper-tooltip {
                --paper-tooltip: {
                    font-size: var(--tooltip-font-size);
                }
                --paper-tooltip-opacity: 1;
                --paper-tooltip-text-color: var(--secondary-dark-color);
                --paper-tooltip-background: var(--primary-light-color);
                box-shadow: 0 2px 4px 0 var(--secondary-dark-color);
                margin-top:9px; 
            }
            /*Paper radio button, checkbox and Switch css*/
            
            paper-radio-button.primary-radio {
                font-size: var(--a-font-size-s);
                --paper-radio-button-size: 22px;
                --paper-radio-button-unchecked-color: var(--divider-color);
                --paper-radio-button-checked-color: var(--brand-color);
                --paper-radio-button-label-color: var(--secondary-dark-color);
            }
            
            paper-checkbox.primary-checkbox {
                --paper-checkbox-unchecked-color: var(--divider-color);
                --paper-checkbox-checked-color: var(--brand-color);
                --paper-checkbox-checkmark-color: var(--primary-light-color);
                --paper-checkbox-checked-ink-color: var(--brand-color);
                --paper-checkbox-label-color: var(--secondary-dark-color);
            }
            
            /*paper-checkbox {
                font-size: var(--a-font-size-s);
            }*/
            
            paper-checkbox.checkbox-light-style[desktop] {
                --paper-checkbox-unchecked-color: var(--primary-light-color);
                --paper-checkbox-checked-color: var(--primary-light-color);
                --paper-checkbox-checkmark-color: var(--brand-color);
                --paper-checkbox-checked-ink-color: var(--primary-light-color);
                --paper-checkbox-unchecked-color: var(--primary-light-color);
            }
            
            paper-toggle-button {
                opacity: 1 !important;
                
                --paper-toggle-button-unchecked-bar: {
                    background-color: var(--divider-color);
                    height: 20px;
                    margin-top: 0px;
                    border-radius: 11px;
                    border: solid 1px var(--divider-color);
                    
                }
                --paper-toggle-button-checked-bar: {
                    opacity:1.0;
                    background-color: var(--brand-color);
                    border: solid 1px var(--brand-color);
                }
                --paper-toggle-button-unchecked-button: {
                    top: 0;
                }
                --paper-toggle-button-checked-button: {
                    background-color: var(--primary-light-color);
                }
                --paper-toggle-button-unchecked-button: focus {
                   opacity:0.5;
                   outline:none;
                }
                --paper-toggle-button-checked-button: focus{
                    opacity:0.5;
                    outline:none;
                   
                }
            }
            /*Paper radio button, checkbox and Switch css*/
            /*Input text box mixin*/
            
            oe-input,
            oe-date oe-input,
            oe-date,
            gold-email-input,
            oe-decimal, oe-textarea, oe-combo, gold-phone-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--ripple-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                font-family: 'Roboto-Regular', 'Noto', sans-serif;
                --paper-input-container-input-color: var(--primary-dark-color);
                --paper-input-container-invalid-color:var(--secondary-color-but);
                --paper-input-container-input: {
                    text-align: initial;
                    line-height: 26px;
                }
                --paper-input-container-label-focus: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-error: {
                    margin-top: 2px;
                }
                /*--paper-input-container-underline-focus: {
                    display: none;
                }*/
                
                --paper-dropdown-menu-icon: {
                    color: var(--secondary-dark-color);
                }
            
            }

            /*To remove required start asterisk*/
            oe-input, oe-combo, oe-date, oe-decimal, oe-radio-group {
                --oe-required-mixin: {
                    display:none;
            }
            }

            /* code to remove asterisk symbol from required inputs */

			/* To control the height and spacing between records of oe-combo */
			oe-combo {
				--paper-item-min-height: 35px;
				--paper-material:{
					max-height: 300px !important;
				}
				--paper-item-focused: {
					background-color: #ccc;
				}
                --errorclass-font-size: 12px;
                --errorclass-padding-top: 2px;
			}
        
            
            oe-decimal {                
                --paper-input-container-input:{
                    font-size: var(--a-font-size-l);
                    line-height: 26px;
                }
                /*added from 1109 */
                --paper-input-container-underline-focus: {
                    display: none;
                }
                /*added from 1109 */
            }
            .currency-prefix {
                font-size: var(--a-font-size-xl);
            }

            oe-input.text-align-right {
                --paper-input-container-label: {
                    text-align: right;
                }
                --paper-input-container-input: {
                    text-align: right;
                }
            }
            
            oe-input.no-border {
                --paper-input-container-underline: {
                    border-bottom: none;
                }
            }
            /*Input text box mixin*/
            
            gold-cc-input,
            gold-email-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--divider-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-container-underline-focus: {
                    display: none;
                }
            }
            
            gold-cc-expiration-input {
                --paper-input-container-underline: {
                    border-bottom-color: var(--divider-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-container-underline-focus: {
                    display: none;
                }
            }
            /* Updated Style for checkbox */
            .newCheckbox {
                --paper-checkbox-unchecked-color: var(--field-unchecked-color);
                --paper-checkbox-checked-ink-size:48px;
            }
            
            /*Drop down text box mixin*/
            /*Sticky content CSS*/
            
            .display-none {
                display: none;
            }
            
            .sticky {
                position: fixed;
                top: 56px;
                width: 100%;
                z-index: 1;
                display: block;
            }
            /*Sticky content CSS*/
            /*Style for oe-info*/
            oe-info.primary-dark-color {
                --oe-info-label: {
                    color: var(--primary-dark-color);
                }
                --oe-info-value: {
                    color: var(--primary-dark-color);
                }
            }

            oe-info.secondary-dark-color-s {
                --oe-info-label: {
                    font-size: var(--a-font-size-s);
                    color: var(--secondary-dark-color);
                }
                --oe-info-value: {
                    font-size: var(--a-font-size-s);
                    color: var(--secondary-dark-color);
                }
            }

            oe-info.secondary-light-color {
                --oe-info-label: {
                    color: var(--secondary-light-color);
                }
                --oe-info-value: {
                    color: var(--secondary-light-color);
                }
            }

            oe-info {
                font-size: inherit;
                --oe-info-label: {
                    padding: 0;
                    font-size: var(--a-font-size-m);
                    color: var(--primary-dark-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                --oe-info-value: {
                    padding: 0;
                    font-size: var(--a-font-size-s);
                    color: var(--disabled-dark-color);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            
            oe-info.display-date {
                --oe-info-value: {
                    font-size: var(--a-font-size-m);
                }
            }
            /*Style for oe-info*/
            /*Fab button with image css*/
            
            .fab-button-image paper-fab {
                --paper-fab-disabled-background: var(--background-secondary);
                --paper-fab-disabled-text: var(--primary-light-color);
                --paper-fab-iron-icon: {
                    color: var(--primary-light-color);
                }
            }
            
            .fab-button-l {
                width: 67px;
                height: 67px;
            }
            /*Fab button with image css*/
            /*Shadow elevation*/
            
            .elevation-1 {
                box-shadow: 0 -3px 11px 0 var(--secondary-dark-color);
            }
            
            .elevation-2 {
                box-shadow: 0 2px 4px 0 var(--elevation-color);
            }
            
            .elevation-2-inset {
                box-shadow: 0 2px 4px 0 var(--elevation-color) inset;
            }
            
            .elevation-3 {
                box-shadow: 0 2px 21px 0 var(--elevation-color);
            }
            
            .elevation-4 {
                box-shadow: 0px 20px 40px 10px var(--elevation-color) ;
            } 

            .elevation-5 {
                box-shadow: 0px 3px 4px 0px var(--disabled-dark-color) !important;
            }

            .elevation-inset-card{
                box-shadow: 0 12px 4px -10px var(--elevation-color) inset, 0 -12px 4px -10px var(--elevation-color) inset;
            }

            /*Shadow elevation*/
            /*input text/label css for desktop view*/
            
            .light-text-color-desktop[desktop], .light-input-style {
                /*Added for color on first page*/
                --paper-input-container-shared-input-style_-_color:var(--primary-light-color);

                --paper-input-container-disabled: {
                    opacity: .8;
                }
                --paper-input-container-underline: {
                    border-bottom-color: var(--primary-light-color);
                }
                --paper-input-container-underline-disabled: {
                    border-bottom-color: var(--primary-light-color);
                }
                --paper-dropdown-menu-icon: {
                    color: var(--primary-light-color);
                }
                --paper-input-container-input: {
                    color: var(--primary-light-color);
                    line-height: 26px;
                }
                --paper-input-container-label: {
                    color: var(--primary-light-color);
                }
                --paper-input-container: {
                    color: var(--primary-light-color);
                }
                --paper-input-container-color: var(--secondary-light-color);
                --paper-input-container-label-focus: {
                    color: var(--primary-light-color);
                }
            }
            /*input text/label css for desktop view*/
            
            paper-dropdown-menu {
                --paper-input-container-underline: {
                    border-bottom-color: var(--divider-color);
                }
                --paper-input-container-label: {
                    color: var(--secondary-dark-color);
                }
                --paper-input-container-input: {
                    text-align: initial;
                    line-height: 26px;
                    padding-bottom: 10px;
                }
                --paper-input-container-input-color: var(--primary-dark-color);
                --paper-input-container-label-focus: {
                    color: var(--secondary-dark-color);
                }
            }
            /*Style for paper-tabs*/
            
            paper-tab {
                --paper-tab: {
                    color: var(--secondary-dark-color);
                    font-size: var(--a-font-size-m);
                }
                --paper-tab-ink: var(--brand-color);
                
            }
            paper-tabs {
                --paper-tabs-selection-bar-color: var(--brand-color);
                font-size: inherit;
                transition: all 0.4s;
               
            }
            paper-tab:hover{
                color: var(--brand-color);
                opacity:0.6;
            }
            
            paper-tabs paper-tab.iron-selected {
                color: var(--brand-color);
            }
            paper-tabs paper-tab.iron-selected:hover{
                color: var(--brand-color);
                opacity:0.6;                 
            }
            @media (max-width:1024px){
                paper-tab:hover{
                    opacity:1 !important;
                }
            }
            /*currency formatting css*/
            
            .currency-decimal,
            .currency-symbol {
                font-size: .78em;
            }
            /*currency formatting css*/
            
            .currency-decimal,
            .currency-symbol {
                font-size: .78em;
            }
            /*currency formatting css*/
            /*Style for overlay of profile page in desktop/tablet mode*/
            
            .profile-overlay-tab-desktop {
                background-color: var(--disabled-dark-color);
                color: var(--primary-dark-color);
                text-align: left;
                position: fixed;
                width: 100%;
                height: 100vh;
                top: 64px;
                left: 0px;
                z-index: 1;
            }
            
            :dir(rtl) .profile-overlay-tab-desktop {
                background-color: var(--disabled-dark-color);
                color: var(--primary-dark-color);
                text-align: right;
                position: fixed;
                width: 100%;
                height: 100vh;
                top: 64px;
                right: 0px;
                left: auto;
                z-index: 1;
            }
            
            .profile-content {
                height: Calc(100vh - 64px);
                overflow: auto;
            }
            /*Style for overlay of profile page in desktop/tablet mode*/
            /*Registration/onboarding skewed banner*/
            
            div.banner-skew {
                position: absolute;
                top: 6.6vw;
                width: 100%;
                right: 0;
                min-height: 440px;
                background: var(--brand-color);
                transform: skewy(8deg);
                overflow:hidden;
            }
            
            div.banner-skew:before {
                content: "";
                position: absolute;
                width: 100%;
                top:7vw;
                min-height:440px;
                transform: skewy(-8deg);
                background-image: var( --dashboard_bg_texture);
                background-repeat: no-repeat;
                filter: brightness(1.5);
                background-size: 200%;
                background-position: bottom;
            }

            :dir(rtl) div.banner-skew {
                position: absolute;
                top: 6.6vw;
                width: 100%;
                left: 0;
                right: auto;
                min-height: 440px;
                transform: skewy(-8deg);
            }

            /*Registration/onboarding skewed banner*/
            
            .page-left-image {
                background: var( --background-image);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
            }
            
            paper-card,
            oe-input,
            sb-phone,
            oe-date,
            gold-cc-input,
            gold-email-input {
                width: 100%;
            }
            /*Paper card background light*/
            
            paper-card {
                background: var(--primary-light-color);
            }
            /*Paper button background light*/
            paper-button {
                --paper-button: {
                    font-size: var(--a-button-font-size);
                }
            }
            
            paper-button i18n-msg {
                color: var(--flat-button-color);
                font-family: var(--medium-font);
            }
            
            
            paper-button i18n-msg {
                color: var(--flat-button-color);
                font-family: var(--medium-font);
            }
            paper-button : focus{
                opacity :0.5;
            }
            
            paper-toolbar.header-style {
                --paper-toolbar-background: var(--primary-light-color);
                --paper-toolbar-color: var(--primary-dark-color);
                --paper-toolbar: {
                    box-shadow: 0px 0px 0px;
                }
            }
            
            paper-toolbar {
                --paper-toolbar-background: var(--primary-light-color);
                --paper-toolbar-color: var(--primary-dark-color);
                --paper-toolbar: {
                    box-shadow: 0px 0px 0px;
                }
                ;
                --paper-toolbar-content: {
                    padding: 0px !important;
                }
            }

            paper-toolbar[phone] {
                padding-top: 26px !important;
                height: 76px !important;
            }
            
            paper-item {
                --paper-item: {
                    font-size: unset;
                }
            }
            
            paper-item {
                --paper-item-focused-before: {
                    background: transparent;
                }
                
            }
            
            .listing-1 .listing-items:nth-child(even) {
                background: var(--brand-color-50);
            }
            
            .listing-2 .listing-items:nth-child(odd) {
                background: var(--brand-color-50);
            }


            /*Phone number having Country code*/
            /*--paper-input-container-underline: {                    
                        border-bottom-color: var(--divider-color);
                    } */
            /*Phone number having Country code*/
            paper-icon-item {
                --paper-item-focused-before: {
                    background: transparent;
                }
            }

            paper-card.round-icon-list-wrapper {
                box-shadow: var(--shadow-elevation-1);
                border-radius: 6px;
            }
            
            paper-icon-item.round-content-list {
                --paper-item-icon-width: 80px;
            }
            /*to fix the footer at bottom having button*/
            
            .fixed-footer {
                position: absolute;
                bottom: calc(0vh - -16px);
                left: 0;
                right: 0;
            }
            
            :dir(rtl) .fixed-footer {
                position: absolute;
                bottom: calc(0vh - -16px);
                right: 0;
                left: 0;
            }
            
            .fixed-footer-container {
                padding-bottom: 56px;
            }
            
            .display-flex-wrap {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }
            
            .content-wrapper[phone] {
                min-height: calc(100vh - 56px);
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

        .container {
                min-height: calc(100vh - 56px);
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-wrap: wrap;
            }

            .container[desktop] {
                min-height: calc(100vh - 136px);                
            }

            .justify-content-space-bwn {
                -webkit-justify-content: space-between;
                justify-content: space-between;
            }

            .page-container-min-height {
                min-height: calc(100vh - 64px);
            }
            
            .page-container-min-height[phone] {
                min-height: calc(100vh - 56px);
            }
            /*flex align items end*/
            
            .align-end {
                align-self: flex-end;
            }
            /*flex align items start*/
            
            .align-start {
                align-self: flex-start;
                align-items: start;
            }
            /*to fix the footer at bottom having button*/
            
            .body {
                font-size: var(--body-font-size);
                /*changes from 1109 */
                /*font-family: 'Roboto-Regular', 'Noto', sans-serif;*/
                /*added from 1109 */
                font-family: 'SF Pro Display', 'Noto', sans-serif;
                margin: 0;
                position: relative;
                background-color: var(--primary-light-color);
                height: initial;
            }
            
            html,
            body {
                height: 100%;
                margin: 0;
            }
            
            paper-scroll-header-panel {
                height: 100% !important;
            }
            /*.header-style {
                background: var(--primary-light-color);
                box-shadow: none;
            }*/
            
            .back-button paper-icon-button {
                padding: 0;
                width: 24px;
                height: 24px;
            }
            
            .page-header-text-wrapper {
                font-size: var(--a-font-size-m);
                letter-spacing: -0.41px;
                font-weight: var(--font-weight-bold);
                text-align: center;
                padding: 4px 0 0 0;
            }
            
            .banner-icon-wrapper {
                background: var(--background-secondary);
                width: 154px;
                height: 154px;
                border-radius: 50%;
                margin: 10vh auto;
            }
            
            .banner-icon-text {
                color: var(--primary-dark-color);
                font-size: var(--a-font-size-m);
                width: 42%;
                margin-top: -20px;
            }
            
            .unfocused-line.paper-input-container {
                background-color: var(--divider-color);
            }
            /*Registration image Verification*/
            /*.banner-image-verify {
                background: var(--Img_sprite_graphics); no-repeat;
                width: 154px;
                height: 154px;
                margin: 4vh auto;
                background-size: cover;
            }*/
            
            .banner-image-verify {
                width: 154px;
                height: 154px;
                margin: 4vh auto;
            }

            .page-left-image-direct-banking {
                background: var(--background_Image_sd);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: bottom;
            }   

            .mainpage-ev-login-admin {
                background-image: var(--login_background);
                height: 100%;
                width: 100%;
            }

            .image-div-profile-details{
				background-size:100% 100%;
				background-image: var(--dashboard_bg);
			}
            
            .image-div-corporate-profile-main {
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
            }

            .banner-image-savings-reg {
                background: var(--Saving_account_graphics_3) no-repeat;
                width: 154px;
                height: 154px;
                background-size: cover;
                background-position: 0 0;
            }
            
            .banner-image-savings {
                background:var(--Img_sprite_graphics) no-repeat;
                width: 130px;
                height: 130px;
                background-size: cover;
                background-position: 0 -130px;
            }
            /*Registration image Verification*/
            
            .profile-pic-banner {
                --iron-icon-width: 100%;
                --iron-icon-height: 100%;
            }
            
            .banner-profile-image-1 {
                border: 2px solid var(--primary-light-color);
                width: 174px;
                height: 174px;
                border-radius: 50%;
                margin-top: -87px;
            }
            
            .round-button {
                background: var(--brand-color);
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: 0 auto;
            }
            
            .round-button-selected {
                background: var(--primary-light-color);
                width: 80px;
                height: 80px;
                border-radius: 50%;
                margin: 0 auto;
            }
            /*Round button Style with icon*/
            
            .round-button > iron-icon {
                --iron-icon-width: 31px;
                --iron-icon-height: 31px;
                --iron-icon-fill-color: var(--primary-light-color);
            }
            
            .round-button-selected > iron-icon {
                --iron-icon-width: 31px;
                --iron-icon-height: 31px;
                --iron-icon-fill-color: var(--brand-color);
            }
            
            .round-button > iron-icon[icon="Ico_aadhar_36"] {
                --iron-icon-width: 49px;
                --iron-icon-height: 44px;
            }
            
            .split-eqaully {
                flex: 1;
            }
            /*Close button to pop up*/
            
            .pop-over-close-button {
                position: relative;
                top: 4px;
                right: 4px;
            }
            
            :dir(rtl) .pop-over-close-button {
                position: relative;
                top: 4px;
                left: 4px;
                right: auto;
            }
            
            /*OTP verify input style*/
            
            *[dir=ltr] .otp-verify-wrapper oe-input {
                width: 30px;
                text-align: center;
                margin-right: 8px;
                --paper-input-container-input: {
                    text-align: center;
                }
            }
            *[dir=rtl] .otp-verify-wrapper oe-input {
                width: 30px;
                text-align: center;
                margin-left: 8px;
                --paper-input-container-input: {
                    text-align: center;
                }
            }

            /*OTP verify input style*/
            /*icon styling*/
            /*Success icon styling*/
            
            .success-icon-m {
                --iron-icon-width: 48px;
                --iron-icon-height: 48px;
                --iron-icon-fill-color: var(--success-label-color);
            }
            
            .iron-icon-style-m {
                width: 32px;
                height: 32px;
                min-width: 32px;
            }
            
            .iron-icon-style-l {
                width: 42px;
                height: 42px;
                min-width: 42px;
            }
            
            .iron-icon-style-xxl {
                width: 72px;
                height: 72px;
                min-width: 72px;
            }

            .iron-icon-style-xl {
                width: 52px;
                height: 52px;
                min-width: 52px;
            }
            
            .iron-icon-style-s {
                width: 22px;
                height: 22px;
                min-width: 22px;
            }
			.iron-icon-color{
				color: var(--brand-color) !important;
			}
			iron-icon.circle-unchecked{
				color: var(--divider-color);
			}
			iron-icon.circle-checked{
				color: var(--brand-color);
			}
            /*icon styling*/
            /*Style for paper-icon with left and right padding zero. This is for twitter, facebook, google plus icons, so that their alignment will not break in small screen devices like Galaxy S5.*/
            
            *[dir=ltr] paper-icon-button.padding-lr-zero {
                --paper-icon-button: {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    width: 24;
                }
            }
            *[dir=rtl] paper-icon-button.padding-lr-zero {
                --paper-icon-button: {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                    width: 24;
                }
            }
            paper-icon-button.paper-icon-button-no-ink {
            --paper-icon-button-ink-color:transparent;
            }

            /*frequently used styles*/
            /*white Background CSS*/
            
            .light-background {
                background: var(--primary-light-color);
            }
            /*Brand Background CSS*/
            
            .brand-background-color {
                background: var(--brand-color);
            }
            
            .secondary-brand-background {
                background: var(--secondary-brand-color);
            }
            /*Gray background CSS*/
            
            .background-primary {
                background: var(--background-primary);
            }
            
            .background-secondary {
                background: var(--background-secondary);
            }
            
            .background-transparent[desktop] {
                background: transparent;
            }
            
            .secondary-dark-background {
                background: var(--secondary-dark-color);
            }
            
            .row-flex-container {
                -ms-box-orient: horizontal;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -webkit-flex-flow: row wrap;
                flex-flow: row wrap;
                -webkit-align-items: stretch;
                align-items: stretch;
            }
            
            .content-align-center {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .content-align-vertical-center {
                display: flex;
                align-items: center;
            }
            
            .content-align-horizontal-end {
                display: flex;
                justify-content: flex-end;
            }
            
            .column-flex-container {
                -ms-box-orient: vertical;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -moz-flex;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-around;
                justify-content: space-around;
                -webkit-flex-flow: column wrap;
                flex-flow: column wrap;
                -webkit-align-items: stretch;
                align-items: stretch;
            }
            /*Align text*/
            
            .text-align-right {
                text-align: right;
            }
            
            .text-align-left {
                text-align: left;
        
            }
            
            :dir(rtl) .text-align-right {
                text-align: left;
            }
            
            :dir(rtl) .text-align-left {
                text-align: right;
            }
            
            .text-align-center {
                text-align: center;
            }
            
			.text-align-justify {
				text-align: justify;
			}            
            
            .text-transform-uppercase {
                text-transform: uppercase;
            }
            
            .text-transform-lowercase {
                text-transform: lowercase;
            }
            
            .text-transform-capitalize {
                text-transform: capitalize;
            }
            
            .text-overflow-ellipsis {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
            .full-width {
                width: 100% !important;
            }
            
            .full-height {
                height: 100%;
            }
            
            .full-height-desktop[desktop] {
                min-height: 100vh;
            }
            
            .height-fill-available {
                height: -webkit-fill-available;
            }
            
            .height-auto  {
                height: auto;
            }
            
            .min-height-auto {
                min-height: auto;
            }
            
            .half-width {
                width: 50%;
            }
            
            .margin-zero {
                margin: 0 !important;
            }
            
            :dir(ltr) .margin-r-zero {
                margin-right: 0 !important;
            }
            
            :dir(ltr) .margin-l-zero {
                margin-left: 0 !important;
            }
            
            :dir(rtl) .margin-r-zero {
                margin-left: 0 !important;
            }
            
            :dir(rtl) .margin-l-zero {
                margin-right: 0 !important;
            }
            
            .margin-t-zero {
                margin-top: 0 !important;
            }
            
            .margin-b-zero {
                margin-bottom: 0 !important;
            }
			 .margin-l-zero {
                margin-left: 0 !important;
            }
            
            .padding-zero {
                padding: 0 !important;
            }
            
            .padding-zero-phone[phone] {
                padding: 0;
            }
            
            .padding-b-zero {
                padding-bottom: 0 !important;
            }
            
            :dir(ltr) .padding-r-zero {
                padding-right: 0 !important;
            }
            
            :dir(ltr) .padding-l-zero {
                padding-left: 0 !important;
            }
            
            .padding-lr-zero {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
            
            :dir(rtl) .padding-r-zero {
                padding-left: 0 !important;
            }
            
            :dir(rtl) .padding-l-zero {
                padding-right: 0 !important;
            }

            .padding-t-zero {
                padding-top: 1px !important;
            }
            
            .font-size-inherit {
                font-size: inherit !important;
            }
            
            .font-size-xxs {
                font-size: var(--a-font-size-xxs) !important;
            }
            
            .font-size-xs {
                font-size: var(--a-font-size-xs) !important;
            }
            
            .font-size-s {
                font-size: var(--a-font-size-s) !important;
            }
            
            .font-size-m {
                font-size: var(--a-font-size-m) !important;
            }
            
            .font-size-l {
                font-size: var(--a-font-size-l) !important;
            }
            
            .font-size-xl {
                font-size: var(--a-font-size-xl) !important;
            }
            
            .font-size-xxl {
                font-size: var(--a-font-size-xxl) !important;
            }
            /*Roboto Font families*/
            
            .font-weight-light {
                font-family: var(--light-font) !important;
            }
            
            .font-weight-bold {
                font-family: var(--bold-font) !important;
            }
            
            .font-regular {
                font-family: var(--regular-font) !important;
            }
            
            .font-medium {
                font-family: var(--medium-font) !important;
            }
            
            .font-thin {
                font-family: var(--thin-font) !important;
            }

            .font-light {
                font-family: var(--light-font) !important;
            }
            
            .font-italic {
                font-style: italic !important;
            }
            /*Roboto Font families*/
            
            .overflow-hidden {
                overflow: hidden;
            }
            
            .overflow-x-hidden {
                overflow-x: hidden;
            }
            
            .overflow-y-hidden {
                overflow-y: hidden;
            }
            
            .overflow-visible {
                overflow: visible;
            }
            
            .line-height-medium {
                line-height: 18px;
            }
            
            .line-height-nomal {
                line-height: normal;
            }
            /*Border style */
            
            .border-1px {
                border: 1px solid var(--divider-color);
            }
            
            .border-b-1px {
                border-bottom: 1px solid var(--divider-color);
            }
            
            .border-t-1px {
                border-top: 1px solid var(--divider-color);
            }
            
            .border-l-1px {
                border-left: 1px solid var(--divider-color);
            }
            
            .border-r-1px {
                border-right: 1px solid var(--divider-color);
            }
            
            :dir(rtl) .border-l-1px {
                border-right: 1px solid var(--divider-color);
            }
            
            :dir(rtl) .border-r-1px {
                border-left: 1px solid var(--divider-color);
            }
            
            .border-light-1px {
                border: 1px solid var(--primary-light-color);
            }
            
            .border-round-8px {
                border-radius: 8px;
            }
            
            .border-round-16px {
                border-radius: 16px;
            }
            
            .border-round-24px {
                border-radius: 24px;
            }
            
			.border-round-tl-tr-24px {
                border-radius: 24px 24px 0 0;
            }
            
			.border-round-bl-br-24px {
                border-radius: 0 0 24px 24px;
            }            
            
            .border-round-4px {
                border-radius: 4px;
            }
            
            .border-radius-tl-tr-12px {
                border-radius: 12px 12px 0 0;
            }
            
            .border-radius-bl-br-12px {
                border-radius: 0 0 12px 12px;
            }
            
            .border-radius-0 {
                border-radius: 0;
            }
            
            .border-b-dotted {
                border-bottom: 2px dotted var(--divider-color);
            }
            
            .border-b-dashed {
                border-bottom: 2px dashed var(--divider-color);
            }
            
            .active-border-l {
                border-left: 4px solid var(--brand-color);
            }
            
            :dir(rtl) .active-border-l {
                border-right: 4px solid var(--brand-color);
            }
            .active-border-l-8px {
                border-left: 8px solid var(--brand-color);
            }
            
            :dir(rtl) .active-border-l-8px {
                border-right: 8px solid var(--brand-color);
            }
            
            /*List active element having border*/
            .list-active-item {
                border-left: 4px solid var(--brand-color);
                position: absolute;
                left: 0;
                width: 4px;
                height: 100%;
                top: 0;
            }
            
            :dir(rtl) .list-active-item {
                border-right: 4px solid var(--brand-color);
                position: absolute;
                right: 0;
                left: auto;
                width: 4px;
                height: 100%;
                top: 0;
            }
            /*Border style */
            /*Webcomponent template CSS*/
            /*Color for button*/
            paper-button.primary-button {
                /*Primary button class*/
                /*changes made from 1109*/
                /* background-color: var(--primary-color-but); */
					background-color: #003d4c;
                font-family: var(--medium-font);
                font-size: var(--a-button-font-size);
                box-shadow: none;
                /*box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);*/
                /*changes made from 1109*/
                transition: all 0.4s;
                --paper-button: {
                    color: rgba(255,255,255,1);
                    min-width: 62px;
                    /*height: 36px;*/
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    padding:8px 16px;
                }
               
            }
            paper-button.primary-button:hover {
            /*changes made from 1109*/
                /* background:var(--primary-color-but);*/
			        	background:#025a6e;
                opacity:0.7;
            /*changes made from 1109*/
              }
            
            paper-button.secondary-button {
                /*Secondary button class*/
                /*changes made from 1109*/
                background-color: var(--primary-light-color);
				/* border: 1px solid var( --primary-color-but);*/
				border: 1px solid #003d4c;
                font-family: var(--medium-font);
                /* border:2px solid var( --primary-color-but);*/
				        border:2px solid #003d4c;
                box-shadow: none;
                /*box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19); */
                
                transition: all 0.4s;
                --paper-button: {
                    /*color: var(--secondary-color-but) !important;*/
					color: #003d4c;
                    min-width: 62px;
                    height: 36px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    padding:8px 16px;
                    margin-right:10px;
                }
                /*changes made from 1109*/
            }
            paper-button.secondary-button:hover {
                background: var( --seconday-button-hover-color);
                opacity:0.6;
              }
            
            paper-button.flat-button {
                /*flat primary button class*/
                font-family: var(--medium-font);
                --paper-button: {
                    color: var(--primary-dark-color);
                    min-width: 140px;
                    height: 40px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    color: var(--brand-color);
                }
            }
            paper-button.flat-button:hover{
                opacity:0.7;
            }
            paper-button.supporting-button {
                /*Supporting Action button class*/
                background-color: var(--primary-light-color);
                --paper-button: {
                    color: var(--primary-dark-color);
                    min-width: 140px;
                    height: 40px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                    color: var(--brand-color);
                    text-transform: inherit;
                    padding: 0;
                    margin: 0;
                }
            }
            paper-button.supporting-button:hover{
                opacity:0.7;
            }
            /*Color for primry button*/
            /*Icons critical style(Triangle icon)*/
            app-dialog.header-brand-color {
                --dialog-background-color: {
                                background-color: var(--brand-color);
                }
                --dialog-text-color: {
                                color: var(--background-primary);
                }
            }

            .critical-icon {
                color: var(--critical-label-color);
                width: 36px;
                height: 31px;
            }
            /*Text brand color*/
            
            .text-brand-color {
                color: var(--brand-color) !important;
            }
            /*Banner brand color*/
            
            .text-decoration-none {
                text-decoration: none;
            }
            
            .banner-brand {
                background: var(--brand-color);
                min-height: 140px;
                background-image: var( --dashboard_bg_texture)
                background-repeat-y: no-repeat;
                background-position: center;
            }
            
            .light-banner {
                background: var(--primary-light-color);
                min-height: 240px;
            }
            /*Style for text in primary-light-color*/
            
            .primary-light-color {
                color: var(--primary-light-color);
            }
            /*Title Style*/
            
            .title-dark {
                color: var(--primary-dark-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-l);
            }
            
            .title-light {
                color: var(--primary-light-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-l);
            }
            /*Header Style*/
            
            .header-dark {
                color: var(--primary-dark-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-m);
                padding: 16px 16px 0 16px;
                display: flex;
            }
            
            .header-light {
                color: var(--primary-light-color);
                font-family: var(--medium-font);
                font-size: var(--a-font-size-m);
                padding: 16px 16px 0 16px;
            }
            /*Primary text css*/
            
            .primary-dark-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-s {
                font-size: var(--a-font-size-s);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-m {
                font-size: var(--a-font-size-m);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-l {
                font-size: var(--a-font-size-l);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--primary-dark-color);
            }
            
            .primary-dark-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-dark-color);
                font-family: var(--thin-font);
            }
            
            .primary-dark-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--primary-dark-color);
            }
            
            .primary-light-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-desktop[desktop] {
                color: var(--primary-light-color);
            }
            
            .primary-light-text-s {
                font-size: var(--a-font-size-s);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-m {
                font-size: var(--a-font-size-m);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-l {
                font-size: var(--a-font-size-l);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--primary-light-color);
                font-family: var(--light-font);
            }
            
            .primary-light-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-light-color);
            }
            
            .primary-light-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--primary-light-color);
                font-family: var(--thin-font);
            }
            /*secondary text css*/
            
            .secondary-dark-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-s {
                font-size: var(--a-font-size-s);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-m {
                font-size: var(--a-font-size-m);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-l {
                font-size: var(--a-font-size-l);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--secondary-dark-color);
            }
            
            .secondary-dark-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--secondary-dark-color);
            }
            
            .secondary-light-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-s {
                font-size: var(--a-font-size-s);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-m {
                font-size: var(--a-font-size-m);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-l {
                font-size: var(--a-font-size-l);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--secondary-light-color);
            }
            
            .secondary-light-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--secondary-light-color);
            }
            /* outstanding text CSS */
            
            .outstanding-text-xs {
                font-size: var(--a-font-size-xs);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-s {
                font-size: var(--a-font-size-s);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-m {
                font-size: var(--a-font-size-m);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-l {
                font-size: var(--a-font-size-l);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-xl {
                font-size: var(--a-font-size-xl);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-xxl {
                font-size: var(--a-font-size-xxl);
                color: var(--outstanding-color);
            }
            
            .outstanding-text-xxxl {
                font-size: var(--a-font-size-xxxl);
                color: var(--outstanding-color);
            }
            /* outstanding text CSS */
            /* position related styling */
            
            .position-relative {
                position: relative;
            }
            
            .position-absolute {
                position: absolute;
            }

            .position-absolute-cover {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
            }
            
            .position-submerged {
                position: fixed;
                position: -webkit-sticky;
                bottom: -2%;
                right: 0%;
                left: 0%;
            }
            
            :dir(rtl) .position-absolute-cover {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
            
            :dir(rtl) .position-submerged {
                position: fixed;
                position: -webkit-sticky;
                bottom: -2%;
                left: 0%;
                right: 0%;
            }
            /*visiblity related class */
            
            .display {
                display: none;
            }
            
            .display-block {
                display: block;
            }
            
            .display-flex {
                display: flex;
            }
            
            .display-inline {
                display: inline;
            }
            /*Disabled black color*/
            
            .disabled-dark-color {
                color: var(--disabled-dark-color);
            }
            /*Disabled white color*/
            
            .disabled-light-color {
                color: var(--disabled-light-color);
            }
            /*Notification text color*/
            
            .warning-color {
                color: var(--warning-label-color);
            }
            
            .critical-color {
            /* changes from 1109*/
                /* color: var(--critical-label-color); */
				color: var(--primary-light-color);
				
            }
			.critical-color-new {
                color: var(--critical-label-color);
            }
            
            .success-color {
                color: var(--success-label-color);
            }
            
            .info-color {
                color: var(--info-label-color);
            }
            
            .outstanding-color {
                color: var(--outstanding-color);
            }
            
            .credited-text-color {
                color: var(--chart-circle-color-light-1);
            }
            /*Notification text color*/
            /*Special phrase text*/
            
            .special-light-phrase-text-xs {
                color: var(--primary-light-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--light-font);
            }
            /*Special phrase text Amount*/
            
            .special-dark-Amount-text-xs {
                color: var(--primary-dark-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--medium-font);
            }
            
            .special-colored-Amount-text-xs {
                color: var(--critical-label-color);
                font-size: var(--a-font-size-xl);
                font-family: var(--medium-font);
            }
            /*input Validation Messages style*/
            
            .input-validation-message {
                font-size: var(--a-font-size-xs);
                color: var(--critical-label-color);
            }
            /*Success-failure text style*/
            
            .success-failure-text {
                font-size: var(--a-font-size-xxl);
                color: var(--primary-dark-color);
                font-family: var(--light-font);
            }
            /*Horizontal Scroll content*/
            
            .horizontal-scroll {
                overflow-x: auto;
                white-space: nowrap;
            }
            
            .horizontal-scroll[phone]::-webkit-scrollbar {
                height: 0px;
                background: transparent;
            }
            
            .horizontal-scroll[tablet]::-webkit-scrollbar {
                height: 0px;
                background: transparent;
            }
            
            .profile-image-wrapper {
                background: var(--backgroud-scondary);
                width: 112px;
                height: 112px;
                border-radius: 50%;
            }
            
            
            .chips-option-unSelected {
                background-color: transparent;
                color: var(--disabled-dark-color);
                padding: 7px 14px 8px;
                margin: 4px 8px 4px 0;
                font-size: var(--a-font-size-m);
                display: inline-block;
                border: 1px solid var(--outline-divider-color);
                border-radius: 51px;
                min-width: 18px;
                text-align: center;
                cursor: pointer;
                outline: none;
            }
            
            .chips-option-selected {
                background-color: var(--brand-color);
                color: var(--primary-light-color);
                border: 1px solid var(--brand-color);
                cursor: inherit;
            }
            
            .chips-option-unSelected:last-child {
                margin-right: 0;
            }
            
            :dir(rtl) .chips-option-unSelected:last-child {
                margin-left: 0;
            }
            
            paper-slider {
                --paper-slider-knob-color: var(--primary-light-color);
                --paper-slider-active-color: var(--brand-color);
                --paper-slider-pin-color: var(--brand-color);
                --paper-slider-knob-start-border-color: var(--primary-light-color);
                --paper-slider-pin-start-color: var(--brand-color);
                --paper-slider-height: 1px;
                --paper-slider-secondary-color: var( --secondary-light-color);
            }
            
            .brand-icon {
                --iron-icon-width: 48px;
                --iron-icon-height: 48px;
                --iron-icon-fill-color: var(--brand-color);
            }
            
            @media (min-width: 480px) {
                .divider-cell {
                    border-right: 1px solid var(--divider-color);
                }
                :dir(rtl) .divider-cell {
                    border-left: 1px solid var(--divider-color);
                }
            }
            
            .dynamic-Container[extra-wide-layout] {
                padding-top: 0;
                background-color: var(--primary-light-color);
            }
            
            .dynamic-Container[wide-layout] {
                padding-top: 0;
                background-color: var(--primary-light-color);
            }
            
            .dynamic-Container {
                background-color: var(--background-primary);
            }
            
            .page-container[extra-wide-layout],
            .page-container[wide-layout] {
                background-color: var(--background-primary);
                border-radius: 2px;
                padding: 16px 16px 0px 16px;
            }
            
            .info-container {
                display: inline;
                padding: 3px 12px 3px 3px;
            }
            /* not reviwed */
            
            .boxContent {
                padding-left: 48px;
                border-radius: 5px 5px 0 0;
            }
            
            :dir(rtl) .boxContent {
                padding-right: 48px;
                border-radius: 5px 5px 0 0;
            }
            
            .boxContainer {
                display: inline-block;
                background-color: var(--primary-light-color);
            }
            
            .zig-zag-bottom-border {
                position: absolute;
                bottom: -14px;
                background-image: var(--triangle);
                background-repeat: repeat-x;
                height: 15px;
                background-size: 15px;
                margin: 0;
                left: 0;
                width: 100% !important;
            }
            
            .container-left-padding {
                padding-left: 8px;
            }
            
            .more-menu,
            .position-r-t {
                position: absolute;
                right: 8px;
                top: 8px;
            }
            
            .close-button {
                position: absolute;
                right: 0;
                z-index: 1;
            }
            
            :dir(rtl) .zig-zag-bottom-border {
                position: absolute;
                bottom: -14px;
                background-image: var(--triangle);
                background-repeat: repeat-x;
                height: 15px;
                background-size: 15px;
                margin: 0;
                right: 0;
                left: auto;
                width: 100% !important;
            }
            
            :dir(rtl) .container-left-padding {
                padding-right: 8px;
            }
            
            :dir(rtl) .more-menu,
            :dir(rtl) .position-r-t {
                position: absolute;
                left: 8px;
                top: 8px;
                right: auto;
            }
            
            :dir(rtl) .close-button {
                position: absolute;
                left: 0;
                z-index: 1;
                right: auto;
            }

            .menu {
                position: absolute;
                top: 0;
            }
            
            .padding-r-l {
                padding-right: 32px;
                padding-left: 32px;
            }
            
            /* not reviwed */
            /* Upcoming Bills Page*/
            /*Webcomponent template CSS*/
            /*frequently used styles*/
            
            .position-absolute-t-r {
                position: absolute;
                top: 0;
                right: 0;
            }
            
            :dir(rtl) .position-absolute-t-r {
                position: absolute;
                top: 0;
                left: 0;
                right: auto;
            }
            /*Need to be reviewed from Nikhil*/
            
            .circle {
                width: 80px;
                height: 80px;
                border: 1px solid;
                border-radius: 50%;
                background: var(--brand-color);
                color: var(--brand-color)
            }
            /*Need to be reviewed from Nikhil*/
            
            .boxHeader {
                color: var(--primary-light-color);
            }

            .box-app-box-chip-list {
                font-size: 15em;
                background-color: var(--brand-color-200);
                text-align: center;
                cursor: pointer;
                height: 120px;
                margin: 3px;
                width: 120px !important;
                color: var(--primary-light-color);
                transform: skew(1);
                background-image: var( --dashboard_bg_texture);
                background-repeat: no-repeat;
                background-position: bottom;
                background-size: 189% 50px;
                transform: scale(1);
                border-radius: 2px;
            }

            .box-app-box-chip-list:hover {
                background-color: var(--outstanding-color);
                transform: scale(1.05);
                transition: transform .1s;
            }
            
            .flex-1 {
                flex: 1;
            }
            
            .chart-circle-color-light-1, .random-light-text-1 {
                color: var(--chart-circle-color-light-1) !important
            }

            .random-light-text-2 {
                color: var(--chart-circle-color-light-2);
            }

            .random-light-text-3 {
                color: var(--chart-circle-color-light-3);
            }

            .random-light-text-4 {
                color: var(--chart-circle-color-light-4);
            }

            .random-light-text-5 {
                color: var(--chart-circle-color-light-5);
            }

            .random-light-text-6 {
                color: var(--chart-circle-color-light-6);
            }

            .random-light-text-7 {
                color: var(--chart-circle-color-light-7);
            }

            .background-light-1, .background-light-1.card-background {
                background: var(--chart-circle-color-light-1) !important
            }

            .background-light-2, .background-light-2.card-background {
                background: var(--chart-circle-color-light-2) !important
            }

            .background-light-3, .background-light-3.card-background {
                background: var(--chart-circle-color-light-3) !important
            }

            .background-light-4, .background-light-4.card-background {
                background: var(--chart-circle-color-light-4) !important
            }

            .background-light-5, .background-light-5.card-background {
                background: var(--chart-circle-color-light-5) !important
            }

            .background-light-6, .background-light-6.card-background {
                background: var(--chart-circle-color-light-6) !important
            }

            .background-light-7, .background-light-7.card-background {
                background: var(--chart-circle-color-light-7) !important
            }
            
            .iron-icon-style-xs {
                width: 18px;
                height: 18px
            }
            
            .half-width-desktop[desktop] {
                width: 50%;
            }
            
            .icon-border-light {
                border-radius: 50%;
                background-color: var(--primary-light-color);
            }
            
            .fab-button-wrapper {
                width: 92px;
            }
            
            .cursor-pointer {
                cursor: pointer;
            }
            /*Used in Onboarding profile pic*/
            
            app-fab.icon-cover-app-fab {
                --app-fab-icon-style: {
                    height: 100%;
                    width: 100%;
                    position: absolute;
                }
            }
            /*Used in Onboarding profile pic*/
            
            /*Accourdian arrow icon display effect on hover*/
            .mainContainer:hover .data-row,
            .mainContainer:focus .data-row,
            .action-button-focused .data-row {
                background-color: var(--background-primary);
            }

            .mainContainer.iron-selected:hover .data-row, 
            .mainContainer.iron-selected:focus .data-row {
                background-color: inherit;
            }
            
            
            .mainContainer:hover .icon-visible, .mainContainer:hover .list-action-button, .mainContainer.iron-selected:hover .hide-value, .mainContainer.iron-selected .hide-value,
            .mainContainer:focus .icon-visible, .mainContainer:focus .list-action-button, .mainContainer.iron-selected:focus .hide-value, .mainContainer.iron-selected .hide-value,
            .action-button-focused .icon-visible, .action-button-focused .list-action-button, .action-button-focused .hide-value {
                display: flex !important;
            }

            .mainContainer:hover .icon-visible[selected], .mainContainer:hover .hide-value, .mainContainer.iron-selected:hover .list-action-button, .mainContainer.iron-selected .list-action-button,
            .mainContainer:focus .icon-visible[selected], .mainContainer:focus .hide-value, .mainContainer.iron-selected:focus .list-action-button, .mainContainer.iron-selected .list-action-button {
                display: none !important;
            }

            .mainContainer:hover .icon-disable[selected],
            .mainContainer:focus .icon-disable[selected] {
                display: none !important;
            }
            /*Accourdian arrow icon display effect on hover*/

            
            .item-selected {
                background: var(--brand-color);
                color: var(--primary-light-color);
                font-size: var(--a-font-size-m);
                border-radius: 12px;
            }
            
            .item-unselected {
                color: var(--secondary-dark-color);
                font-size: var(--a-font-size-m);
                border-radius: 12px;
                border: 1px solid var(--divider-color);
            }
            
            .overdue {
                background-color: var(--primary-color-but);
                padding: 2px 8px;
            }
            
            .searchBox {
                //border: 1px solid var(--divider-color); BY Satya
                border-radius: 48px;
                //height: 42px; BY Satya
				height: 40px;
                width: 250px;
                padding-left: 8px;
            }
            
            :dir(rtl) .searchBox {
                border: 1px solid var(--divider-color);
                border-radius: 48px;
                height: 42px;
                width: 250px;
                padding-right: 8px;
            }
            /* MicroInteraction oe-input */
           .newInput {
            max-width:25%;
            --paper-input-container-label_-_color: var(--field-unchecked-color);
            --paper-input-container-color:var(--field-unchecked-color);
        }

            .searchBox oe-input {
                --paper-input-container: {
                    padding-bottom: 0;
                    height: 1px;
                };
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--primary-light-color);
                };
                --paper-input-container-underline-focus: {
                    display: none;
                };
            }

            paper-item {
                --paper-item: {
                    font-size: inherit;
                }
                --paper-item-body: {
                    font-size: inherit;
                }
            }

            
            .banner-background-primary {
                background-size: 100% 100%;
                background-image: var(--dashboard_bg);
                background-repeat: no-repeat;
            }

            .image-div-dashboard{
				background-size:100% 100%;
				min-height:212px;
				background-image: var(--dashboard_bg);
			}

			.image-div-dashboard[desktop]{
				min-height:100px;
			}
            
            .hybrid-screens .banner-background-primary {
                background-position: bottom;
                background-size: 100% 45%;
            }
            /*Disable copy from application*/
            
            [phone] *:not(input):not(textarea) {
                -webkit-user-select: none;
                /* disable selection/Copy of UIWebView */
                -webkit-touch-callout: none;
                /* disable the IOS popup when long-press on a link */
            }
            /*Disable copy from application*/
        
            .white-space-initial {              /*Break line into multiple lines*/
                white-space: initial;
            }
            
            
            .ie-display {
                display: none !important;
            }

            /*Css hack for IE*/
            @media all and (-ms-high-contrast:none)
            {
                oe-input {
                    --paper-input-container-label: {
                        top: -4px;
                    }
                }
                .height {
                    height:100px;
                }
                paper-item-body {
                    height: 72px;
                }
                
                paper-item-body.height-auto {
                    height: auto;
                }
                
                paper-item {
                    --paper-item: {
                        font-size: inherit;
                    }
                }
                .ie-display {
                    display: block !important;
                }
                
                paper-item-body.ie-preferences {
                    flex-basis: auto !important;
                }
                
                paper-tooltip {                    
                    --paper-tooltip-opacity: 1;
                    --paper-tooltip-text-color: var(--secondary-dark-color);
                    --paper-tooltip-background: var(--primary-light-color);
                    box-shadow: 0 2px 4px 0 var(--secondary-dark-color);
                    background: var(--primary-light-color);
                    font-size: var(--tooltip-font-size);
                }
                paper-tooltip .tooltip-container {
                    padding: 8px;   
                }
                .ie-remove-overflow-hidden {
                    overflow: visible;
                }
                
                .dot {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: var(--secondary-dark-color);
                }
                .tabwidth
                {
                    width:173px;
                }

                app-swiper {
                    --app-swipe-height: {
                        height: 89px !important;
                    }
                }

                
                /* enable rtl - start */
                *[dir=rtl] .subheader-wrapper, *[dir=rtl] .mainContent {
                    margin-right: 120px;
                    margin-left: 0 !important;
                }

                *[dir=rtl] .more-button {
                    right:initial;
                    left:0;
                }
                
                
                *[dir=rtl] .text-align-right {
                    text-align: var(--align-left);
                }

                *[dir=rtl] .text-align-left {
                    text-align: var(--align-right);
                }
                /* enable rtl - end */
                
                .set-height-40 {
                    height: 40px;
                    vertical-align: center
                }
                .set-margin-top {
                    margin-top: 26px;
                }
                .set-marginm-top {
                    margin-top: 30px;
                }
                /* To be reviewed with naveen */
                .set-height-85{
                    height: 85px;
                }
                .style-tab {
                    display:table-cell; 
                    padding:8px;
                }
            }
            /*Css hack for IE*/
            
            /*DDA styling*/
            .dda-list-search-screenoff {
                position: absolute;
                overflow: hidden;
                clip: rect(1px 1px 1px 1px);
            } 
            /*DDA styling*/
            /* below style is to hide action buttons on hover from devices. Commenting them for issue 268200 
            *[isiosandroiddevice] {
                display: none !important;
            }*/

            /* styles to make l2t-paper-slider similar to that of paper-carousel in polymer-1 */
            l2t-paper-slider {                
                --paper-slide-font-size: 1px;
                --paper-slide-dot: var(--background-primary);
                --paper-slide-dot-selected: var(--background-secondary);
                --paper-slide-dot-styles: {
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                }
            }
            l2t-paper-slider paper-slide {
                overflow-x: unset;
            }
            
            /* Below styles need to place is required file - when found solution for shadow dom styling - start*/
            /* Styles for deposit flow - app-swiper*/
            .deposit-card-paper-card {
                background-color: var(--card-color-3);
                border-radius: 8px;
                color: var(--primary-light-color);
                min-width: 300px;
                padding: 8px;
                max-width: 350px;
            }
            .deposit-card-more-option{
                background-color: var(--primary-light-color);
                border-radius: 0 6px 6px 0;
                left: 8px;
            }
            
            :dir(rtl) .deposit-card-more-option{
                background-color: var(--primary-light-color);
                border-radius: 0 6px 6px 0;
                right: 8px;
            }
            
            /* Styles for loan flow - app-swiper*/
            .loan-card-paper-card {
                background-color: var(--card-color-3);
                border-radius: 8px;
                color: var(--primary-light-color);
                min-width: 300px;        
                padding: 8px;
                max-width: 350px;
            }
            
            .drop-down-options {
                right: 0;
                top: 40px;
                box-shadow: 0 0 2px 0 var(--tabs-box-shadow-first);
                z-index: 1;
            }

            .card-close-button {
                padding: 10px;
            }

            .drop-down-list-container {
                max-height: 151px;
                overflow: auto;
            }            
            
            .loan-card-more-option{
                background-color: var(--primary-light-color);
                margin-top: -8px;
                margin-bottom: -8px;
                margin-right: -8px;
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
            } 
            :dir(rtl) .loan-card-more-option{
                background-color: var(--primary-light-color);
                margin-top: -8px;
                margin-bottom: -8px;
                margin-left: -8px;
                border-bottom-right-radius: 8px;
                border-top-right-radius: 8px;
            } 
            .chips-option-unSelected.chipTab{
                font-size: var(--a-font-size-s);
                font-family: var(--regular-font);                    
                border-radius: 17px;
                background-position: center;
                transition: background 0.5s;
            }
            .chips-option-unSelected.chipTab:hover {
                background: var(--background-secondary) radial-gradient(circle, transparent 1%, var(--background-secondary) 1%) center/15000%;
                opacity:0.9;
            }
            .chips-option-unSelected.chipTab:active {
                background-color:var(--ripple-color);
                background-size: 100%;
                transition: background 0s; 
            }
            /* Styles for mirroring direction icons like left and right*/
            :dir(rtl) .action-btn-rtl{
                transform: rotate(180deg);
            }
            /* Below styles need to place is required file - when found solution for shadow dom styling - end*/
            /*Styles for accounts list starts here*/
            .account-id, .available-balance-text {
                position:relative;
                top:4.3px;
            }
            /*Styles for accounts list ends here*/ 

            .footer-menu-overlay {
                z-index: 3;
                background-color: var(--disabled-dark-color);
                position: fixed;
                width: 100%;
                bottom: 0;
                top: 0;
                opacity: 0;
                animation: .3s ease 0s normal forwards 1 animateOpacity;
            }            

            @keyframes animateOpacity {
                0%   { opacity: 0; }
                100% { opacity: 1; }
              }

            .outlineOffset:focus{
                outline: 2px auto var(--DDA-outline-color);
                outline-offset: 2px !important;
            }
			/* changes from 1109*/
						.menu-tabs-3,
            :dir(rtl) .menu-tabs-3 {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 0px 16px;
                background: #FFFFFF;
                cursor: pointer;
                color: #636C75;
                min-height: 50px;
                min-width: 106px;
                font-family: SF Pro Display;
                font-style: normal;
                font-weight: 400;
            /*    font-size: 14px;     */
                line-height: 24px;
            }          
            .menu-tabs-3-active,
            :dir(rtl) .menu-tabs-3-active{
                background: #F4F5F7;
                color: #101214;
                font-weight:500;
            }
			/* paper tabs text Color changed 31-03-2020*/
			paper-tabs paper-tab.iron-selected {
                color: #00839B!important;
            }
            paper-tabs paper-tab.iron-selected:hover{
                color: #00839B!important;
            }
			/* paper tabs text Color changed 31-03-2020*/
             .custom-paper-tab-blue {
               
                --paper-tabs-selection-bar-color: transparent; 
                box-shadow: 0 4px 15px 0 var(--tabs-box-shadow-first), 0 6px 4px 0 var(--tabs-box-shadow-second);
                transition: all 0.4s;
                max-width: 200px;
            }
            
             .custom-paper-tab-blue:hover{
                color: var(--brand-color)                    
            }
            paper-tabs  .custom-paper-tab-blue.iron-selected {
                color: var(--brand-color)              
            }
			.align-self-center {
                align-self: center;
            }	
			paper-icon-button#chipClose #icon{
                width:20px;
                height:20px;
            }
			.content-align-vertical-start {
                display: flex;
                align-items: flex-start
            }
			/* Text brand color changed 31-03-2020*/
			.text-brand-color {
			color: #003d4c !important;
            }
			/* Text brand color changed 31-03-2020*/
			
			 /* Chips button changed 31-03-2020*/
				.chips-option-selected {
                background-color: #025a6e;
                border: 1px solid #025a6e;
            }
			/* Chips button changed 31-03-2020*/
			 /* Styles for mirroring direction icons like left and right*/
            :dir(rtl) .action-btn-rtl{
                transform: rotate(180deg);
            }
            /* Below styles need to place is required file - when found solution for shadow dom styling - end*/
            /* 03-04-2020 */
            div, h1, h2, h3, h4, h5, h4, span, p, label {
                font-family: SF Pro Display;
            }
			
			paper-tabs {
                --paper-tabs-selection-bar-color: #00839b !important;
            }
			paper-button.secondary-button {
                /*Secondary button class*/
                color: #003d4c !important;
			}
			app-dialog {
				--dialog-background-color_-_background-color: var(--brand-color) !important;
			}
			div.banner-skew {
				top: 6.6vw;
			}
            
            :dir(rtl) div.banner-skew {
				top: 6.6vw;
            }
			/* 03-04-2020 */
			/* 08-04-2020 */
			oe-input,
            oe-date oe-input,
            oe-combo{
                --paper-input-container-label: {
                    color: black;
                }
				--paper-input-container-input: {
                    color: black;
                }
				--paper-input-container: {
                    color: black;
                }
			}
			/* 08-04-2020 */
			
			.font_familySFPro{
                font-family:SF Pro Display;
				
            }
			.heading-3{
				font-size:32px;
			}
			.font-24{
				font-size:24px;
			}
			.font-20{
				font-size:20px;
			}
			.font-18{
				font-size:18px;
			}
			.font-16{
				font-size:16px;
			}
			.font-14{
				font-size:14px;
			}
			.font-13{
				font-size:13px;
			}
			.font-10{
				font-size:10px;
			}
			.font-weight-500{
				font-weight:500;
			}
			
			.font-weight-600{
				font-weight:600;
			}
			.secondary-color{
				color: var(--secondary-color) !important;
			}
			.font-34{
				font-size:34px;
			}
			.font-weight-700{
				font-weight:700;
			}
			.font-weight-400{
				font-weight:400;
			}
			.font-30{
				 /*font-size: var(--a-font-size-30) !important;*/
                 font-size: 30px;
			}
			.margin-30{
				margin:30px;
			}
            .margin-32{
				margin:32px;
			}
            .font-12{
                font-size:12px;
            }
            .font-15{
                font-size:15px;
            }
			.ironiconsmixin{
				--iron-icon-newwidth : 24px;
				--iron-icon-newheight: 24px;
				}
			
			
			paper-button.secondary-button {
                /*Secondary button class*/
                background-color: var(--primary-light-color);
                /* border: 1px solid var( --primary-color-but);*/
                border: 1px solid #003d4c;
                font-family: var(--medium-font);
                /* border:2px solid var( --primary-color-but);*/
                border:2px solid #003d4c;
                /*box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.19);*/
                transition: all 0.4s;
                --paper-button: {
                    /* color: var(--brand-color); */
                    color: #003d4c;
                    min-width: 140px;
                    height: 40px;
                    font-size: var(--a-button-font-size);
                    letter-spacing: 0;
                    line-height: 30px;
                    text-shadow: 0 1px 2px rgba(--button-text-shadow-color);
                }
            }
            paper-button.secondary-button:hover {
                background: var( --seconday-button-hover-color);
                opacity:0.6;
              }
		
			
			
		
            oe-combo#transferFee, oe-combo.combofield {
                --paper-input-container-input: {
                  top:0px!important;
                  left: 15px;
                }
                --paper-input-container-focus-color: #00839B;
				--paper-input-container-label-floating_-_font-size:12px;
				--paper-input-container-input: {
              top: 5px!important;
              left: 20px!important;
              color: #101214;
          }
               }
               oe-combo.custom-combo#currency {
                --paper-input-container_-_background: #F4F5F7;
				--paper-input-container-label-floating_-_font-size:12px;
               }
			   oe-combo.custom-combo#currSelector {
                --paper-input-container_-_background: #F4F5F7;
				--paper-input-container-label-floating_-_font-size:12px;
               }
               /*.custom-combo#subCategory, .custom-combo#mainCategory, .custom-combo#purpose {
                --paper-input-container_-_background: #F4F5F7;
                --paper-input-container-input: {
                    top:10px!important;
                    left: 20px;
                  }
                  --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color)!important;
                }
               }*/

               oe-input#debitNarration, oe-input#additionalInformation, oe-input#uniquerefNumber, oe-input#nickNameVal,oe-input#rejectRemarks, oe-input.inputbox, oe-input#remarks, oe-input#AccountNumber_Counterparty_2, oe-input#AccountNumber_Counterparty_3   {
                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  background: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 4px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 6px!important;  /* For error msg */
                    left: 15px!important;
                }

                --paper-input-container-label-floating: {
                    top: 12px; /* For error msg */
                    left: 0px; /* For error msg */
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                }
                --paper-input-container-underline-focus: {
                            display: none;
                }
                --paper-input-container-underline-disabled: {
                        display: none;
                }
    
                --paper-font-caption: {
                    display: inline-block; /* For error msg */
					 font-size:12px!important;
                }
				--paper-input-container-label_-_padding-left:20px;
				--errorclass-font-size: 12px!important;  /* For error msg */
				--errorclass-padding-top: 20px;  /* For error msg */
     
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: -5px;  /* For error msg */
                   bottom: 0%;
                   margin-top:22px!important;
                   background: #00839B;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                   color: var(--secondary-color) !important;
                }
               }
               #mainCategory , #subCategory , #purpose , #currency , #creditAccountSelector , #debitAccountSelector , #payDay , {
                span.required {
                  --paper-input-container-invalid-color: var(--secondary-color) !important;;
                }
                
            }
			/* 02-04-2020 */
            /* checkbox hover added here */
            paper-checkbox:hover{
                background:rgba(0, 131, 155, 0.15);
              }
              /*end checkbox hover added here */
            oe-date#reversal-date paper-input-container iron-input input{
                padding-left:10px;
                padding-right:10px;
            }
            oe-date#reversal-date {
                width: 319px;
                --paper-input-container_-_border-radius: 4px;
                --paper-input-container_-_min-height: 56px;
            --paper-input-container-input: {
                top: 10px!important;
                left: 20px!important;
                font-weight: 700;
                color: #101214;
            }
            --paper-input-container-underline: {
                display: none;
                border-bottom: 0px!important;
            }
            --paper-input-container-label_-_font-family: SF Pro Display;
            --paper-input-container-label_-_color: #636C75!important;
            --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
        }
        oe-date#reversal-date {
            --paper-input-container-underline:{
                padding-top:8px !important; 
            }
           #file1-div{
                width: 95%;
                margin: 10px auto;
                background: #fff;
                padding: 10px;
            }
          
    }
	
	oe-date#scheduleDate {
                width: 319px;
                --paper-input-container_-_border-radius: 4px;
                --paper-input-container_-_min-height: 56px;
            --paper-input-container-input: {
                top: 3px!important;
                left: 10px!important;
                font-weight: 700;
                color: #101214;
            }
            --paper-input-container-underline: {
                display: none;
                border-bottom: 0px!important;
            }
            --paper-input-container-label_-_font-family: SF Pro Display;
            --paper-input-container-label_-_color: #636C75!important;
            --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
					color: #636C75;
                }
        }

           nbb-combo-selector,new-nbb-combo-selector, account-selector, oe-date {
                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  background: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 6px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 10px;
                    left: 20px;
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B !important;
    
                --paper-input-container-underline: {
                    display: none;
                }
    
                --paper-font-caption: {
                    display: block;
					font-size: 12px;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   
                   /* TURQOISE */
                   
                   background: #00839B;
                   margin-top: 15px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
            }
            /*oe-combo#frequency, oe-combo#times{
                width: 28%;

                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  min-height: 54px;
                  background: #F4F5F7;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 0px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 10px;
                    left: 20px;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color);
                }
    
                --paper-font-caption: {
                    display: none;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   
                   background: #00839B;
                   margin-top: 3px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
				--paper-input-container-label-floating_-_font-size:12px;
            }*/
			
			
			
            oe-combo, oe-input, oe-textarea {
                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 6px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 10px;
                    left: 20px;
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color);
                }
    
                --paper-font-caption: {
                    display: block;
					font-size: 12px;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 0%;
                   bottom: 0%;
                   
                   /* TURQOISE */
                   
                   background: #00839B;
                   margin-top: 15px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
            }
			
			oe-decimal.amtMargin {
				
				--border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                margin-top: -3px;
            --paper-input-container: {
                background: #F4F5F7!important;
				background-color: #F4F5F7!important;
                border-radius: 3px!important;
				padding: 0px!important;
				
                font-size: 16px !important;
                }
            --paper-input-container_-_height:56px!important;
            --paper-input-container_-_width:100%!important;


                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 0px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
					
					top: 3px!important;  /* For error msg */
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px; /* For error msg */
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color);
                }
				
				--paper-font-caption: {
                    display: inline-block; /* For error msg */
					font-size:12px!important; /* For error msg */
                }
				
				--errorclass-font-size: 12px!important;  /* For error msg */
				--errorclass-padding-top: 20px;  /* For error msg */
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: -5px;
                   bottom: 0%;
                   
                   /* TURQOISE */
                   
                   background: #00839B;
                   margin-top: 16px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
			
			}
			
			oe-combo#billerCategoryListDropdown, oe-combo#merchantListDropdown {
      
        /*margin-left: 52px;*/
        --paper-item:{
            font-size:15px;
            }
        --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
		
        --paper-input-container: {
            background: #F4F5F7 !important;
            border-radius: 3px;
            padding-top: 0px!important;
            padding-right: 0px!important;
            padding-bottom: 0px!important;
            padding-left: 10px!important;
            font-size: 16px;
            
            }
			
			--paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

            --paper-input-container-input: {
              top: 0px!important;
              left: 0px!important;
              color: #101214;
          }
			--paper-input-container-label_-_color: #636C75 !IMPORTANT;
          --paper-input-container-label: {
              padding: 0px 0 6px 15px!important;
              font-family: SF Pro Display;
              color: #f4f5f7;
          }
			
		--paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color);
                }
    
                --paper-font-caption: {
                    display: none;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   
                   /* TURQOISE */
                   
                   background: #00839B;
                   margin-top: 3px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
      }
      oe-combo#payDay {
      
        /*margin-left: 52px;*/
        --paper-item:{
            font-size:15px;
            }
        --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282!important;
                --prefix-color: #828282;
                --suffix-color: #828282;
		
        --paper-input-container: {
            background: #F4F5F7 !important;
            border-radius: 3px;
            padding-top: 0px!important;
            padding-right: 0px!important;
            padding-bottom: 0px!important;
            //padding-left: 10px!important;
            font-size: 16px;
            
            }
			
			--paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

            --paper-input-container-input: {
              top: 0px!important;
              left: 0px!important;
              color: #101214;
          }
			--paper-input-container-label_-_color: #636C75 !IMPORTANT;
          --paper-input-container-label: {
              padding: 0px 0 6px 15px!important;
              font-family: SF Pro Display;
              color: #f4f5f7;
          }
			
		--paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color);
                }
    
                --paper-font-caption: {
                    display: none;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   
                   /* TURQOISE */
                   
                   background: #00839B;
                   margin-top: 3px;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
      }
           
            oe-combo, account-selector, nbb-combo-selector, new-nbb-combo-selector {
                --paper-input-container-underline-focus: {
                    margin-top:0px;
                   }
                   --paper-input-container-input_-_left: 24px;
                   --paper-input-container-label-floating_-_left: 8px;
                   --paper-input-container-label: {
                    padding: 0px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }
            }
             .custom-combo#transferFee {
                width: 13%;

                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  background: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 18px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 25px!important;
                    left: 20px!important;
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                }
    
                --paper-font-caption: {
                    display: none;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   margin-top:28px!important;
                   background: #00839B;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                }
            }
            .custom-combo#times {
                width: 13%;

                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  background: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    padding: 18px 0 6px 15px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 25px!important;
                    left: 20px!important;
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                }
    
                --paper-font-caption: {
                    display: none;
                }
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   margin-top:28px!important;
                   background: #00839B;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                    color: #636C75;
                }
            }
            oe-input.custom-combo#amount {
               

                --border-thick: 1px;
                --border-color: #F4F5F7;
                --label-color: #828282;
                --prefix-color: #828282;
                --suffix-color: #828282;
    
                --paper-input-container: {
                  font-family: SF Pro Display!important;
                  font: SF Pro Display!important;
                  padding: 0px 0!important;
                  border-width: 0px;
                  box-sizing: border-box;
                  background-color: #F4F5F7;
                  background: #F4F5F7;
                  min-height: 54px;
                }

                --paper-input-container-suffix: {
                    padding: 4px 10px 0 0 !important;
                }

                --paper-input-container-label: {
                    //padding: 18px 0 6px 15px;
                    padding: 16px 0 0px 0px;
                    font-family: SF Pro Display;
                    color: var(--label-color, #f4f5f7);
                }

                --paper-input-container-input: {
                    top: 15px!important;
                    left: 15px!important;
                    color: #101214;
                }

                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }

                --paper-input-container-focus-color: #00839B;
    
                --paper-input-container-underline: {
                    display: none;
                }
    
                --paper-font-caption: {
                    display: block;
                }
				--errorclass-font-size: 12px!important;  /* For error msg */
				--errorclass-padding-top: 30px;  /* For error msg */
    
                --paper-input-container-underline-focus: {
                   position: absolute;
                   left: 0%;
                   right: 0%;
                   top: 96.43%;
                   bottom: 0%;
                   margin-top:28px!important;
                   background: #00839B;
                  }
    
                --oe-label-mixin: {
                    padding-left: 15px;
                    font-size: 16px!important;
                    color:  #636C75;
                }
            }
            account-selector {
                --paper-input-container-label-floating: {
                    top: 28px;
                    left: 10px;
                }
                --paper-input-container-input_-_left: 15px !important;
                --paper-input-container-input_-_top: 0px !important;
                --paper-input-container-label_-_left: 16px !important;
            }
            .approval-div approver-selector  paper-material{
              padding:20px !important;
            }
            nbb-approval-status.approve-css{
             --paper-material:{
                width: 96.66%;
                height: min-content;
                margin: 10px 0px 0px 20px;
                background: #fff;
            }
            nbb-approval-status.approve-css #approve-paper{
               
              
                 width: 96.66% !important; 
              
                 margin: 10px 0px 0px 63px; 
              
            }
            #approve-paper{
                width: 90.66%; 
                margin: 10px 0px 0px 63px; 
              }
              nbb-success-screen#transfer-confirm .transaction-details{
                padding-top:20px !important;
                padding-bottom:20px !important;
            }
            nbb-success-screen#transfer-confirm
            {
                .transaction-details{
                    padding-top:20px !important;
                    padding-bottom:20px !important;
                }
            }
            oe-combo#select-type-dropdown paper-input-container .underline.is-invalid .focused-line {
                border: unset !important;
               }
               .date1-div oe-date oe-input{
                width: 200px !important;
               }
        }
        paper-progress {
            --paper-progress-height: 8px;
        }
        .list-label{
		background-color:#FAFAFB;
		}
        .teal-color{  
        color: #00839B !important;
       }
			/*for approvals screen*/
		/*oe-input#debit{
			--paper-input-container-label-floating_-_top:8px;
            --paper-input-container-label-floating_-_left:0px;
            --oe-label-mixin_-_padding-left:0px;
		}*/
		/*for approvals screen*/
    .text-transform-uppercase {
                font-family: SF Pro Display
            }

    .text-overflow-ellipsis {
                    font-family: SF Pro Display;
                }
		/* changes from 1109*/
		/* Approval Button Styles */
		.approve-button{
                font-weight: 700;
                font-size: 16px;
                border: 2px solid;
                border-radius: 4px;
                color: #4CA585;
                height: 40px;
                text-transform: initial;
                width: 143px;
                font-family: 'SF Pro Display';
            }
        .reject-button{
                font-weight: 700;
                font-size: 16px;
                border: 2px solid;
                border-radius: 4px;
                color: #DA2128;
                height: 40px;
                text-transform: initial;
                width: 143px;
                font-family: 'SF Pro Display';
            }
        .repair-button{
                color: #7C4182;
                font-weight: 700;
                font-size: 16px;
                border: 2px solid;
                border-radius: 4px;
                height: 40px;
                text-transform: initial;
                width: 142px;
                font-family: 'SF Pro Display';
            }

		.view-details-button{
                background: #003D4C;
                border-radius: 4px;
                color: #F9FCFF;
                font-weight: 700;
                font-size: 16px;
                height: 40px;
                text-transform: initial;
                width: 135px;
                font-family: 'SF Pro Display';
            }
		.change-approver-button{
                color: #003D4C;
                font-weight: 700;
                font-size: 16px;
                border: 2px solid;
                border-radius: 4px;
                height: 40px;
                width: 170px;
                text-transform: initial;
                font-family: 'SF Pro Display';
            }
		/* Approval Button Styles */
		/* for Status Background Colours */
		.yellowStatus{
        padding: 2px 10px;
        height: 20px;
        border-radius: 10px;
        width: fit-content;
		background-color:#F9E7BF;
		color:#A57B1E;
       }
	   .blueStatus{
        padding: 2px 10px;
        height: 20px;
        border-radius: 10px;
        width: fit-content;
		background-color:rgba(29, 167, 245, 0.2);
		color: #292E33;
       }
	   .redStatus{
        padding: 2px 10px;
        height: 20px;
        border-radius: 10px;
        width: fit-content;
		background-color: #F8D3D4;
		color: #DA2128;
       }
	   .greenStatus{
        padding: 2px 10px;
        height: 20px;
        border-radius: 10px;
        width: fit-content;
		background-color: #DDF8EE;
		color: #119365;
       }
	   .icon-color {
					color: var(--brand-color) !important;
				}

        oe-combo.comboStyleField {
            --paper-input-container_-_background: #F4F5F7;
            --paper-input-container-input: {
                top:10px!important;
                left: 20px;
                }
                --paper-input-container-underline: {
                display: none;
                border-bottom-color: var(--divider-color)!important;
            }
        }
        oe-decimal.custom-decimal, oe-input.custom-input {
            --paper-input-container-input_-_top: 0px !important;
            width: 100% !important;
            --oe-label-mixin_-_font-size: 14px !important;
            --oe-label-mixin_-_padding-left: 13px;
            --paper-input-container-shared-input-style_-_padding-left: 13px;
            --paper-input-container_-_padding: initial !important;
            --paper-input-container-underline_-_display: none !important;
            --paper-input-container-underline-focus_-_display: none;
            --paper-input-container-label_-_padding: 7px 0px 7px 13px !important;
            --paper-input-container-shared-input-style_-_line-height: 38px !important;
            --paper-input-container-input_-_line-height: 38px !important;
            --paper-input-container-shared-input-style_-_padding: 0px 0px 0px 13px !important;
            --paper-input-container: {
                background: #F4F5F7 !important;
                border-radius: 3px;
                font-size: 16px;
                height: 38px !important;
            }
        }
        oe-input.custom-input{
            --paper-input-container-shared-input-style_-_padding: 0px !important;
        }
		.wordWrap {
			word-wrap: break-word;
		}
		/* SR Common Styles*/
			  /*.headingStyle{
                font-size: 31px;
                letter-spacing: 0.15px; 
				font-family: 'SF-Pro-Display-Medium';
				font-weight: 700;
              }  */
              .goBackSR{ 
                font-size: 15px;
                font-weight: 500;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 500; 
                line-height: 24px; 
                display: flex;
                align-items: center;
                letter-spacing: 0.15px; 
                color: #00839B;
              }
              /*.secondaryHeadingStyle{
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 700;
                font-size: 19px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0025em;
                color: #101214;
				font-family: 'SF-Pro-Display-Medium';
              }*/
              .secondarysubHeadingStyle{
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0025em;
                color: #101214;
				font-family: 'SF-Pro-Display-Medium';
              }

             .SRPaperCardStyle{
              margin-left: 4%;
              margin-right: 3%;
            }
            .SRTermsANdConditions{
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              display: flex;
              align-items: center;
              letter-spacing: 0.0025em;
			  font-family: 'SF-Pro-Display-Medium';
            }
            .SRTCsubText{
              font-family: 'SF Pro Display';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: #636C75;
            }
            .SRGoBackStyle{
              margin: 15%;
              margin-left: 15%;
            }
			.iron-icon-style-goback{
			color: #04859C;
			width: 24px !important;
			height: 24px !important;
			margin-right: 7% !important;
			}
			.papercardStyle {
				padding: 2% !important;
				padding-left: 11% !important;
				padding-right: 12% !important;
				margin-bottom: 2%;
			}
			/*.previewHeadingStyle{
                font-size: 31px;
                letter-spacing: 0.15px; 
				font-family: 'SF-Pro-Display-Medium';
              } */
			.previewLeftSegmentStyle{
                border-right: 1px solid lightgray;
				line-height: 24px;
				padding: 2% 1%;
              }
			.previewRightSegmentStyle{
                 line-height: 24px;
				 padding: 2% 7%;			
              }		
			.previewInfoStyle{
					font-family: 'SF Pro Display';
					font-style: normal;
					font-weight: 400;
					font-size: 14px;
					line-height: 24px;
					color: #636C75;
			}
			.previewValueStyle{
					font-family: 'SF Pro Display';
					font-style: normal;
					font-weight: 700;
					font-size: 17px;
					line-height: 20px; 
					color: #101214;					
					font-family: 'SF-Pro-Display-Medium';
					margin-bottom: 3%;
			}
			.successSummaryHeading{
					padding: 0px 10px;
					font-family: 'SF Pro Display';
					font-style: normal;
					font-weight: 700;
					font-size: 19px;
					line-height: 24px;
					letter-spacing: 0.0025em;
					color: #101214;					
					font-family: 'SF-Pro-Display-Medium';
			}
			.successIronIcon{
					color: #636C75;
					width: 30px;
					height: 30px;
			}
			/*.largerHeadingStyle{
                font-size: 31px;
                letter-spacing: 0.15px; 
				font-family: 'SF-Pro-Display-Medium';
              } 
				*/
            .listFieldStyle {
                --paper-input-container-focus-color: #00839B;
                --paper-input-container_-_background: #F4F5F7;
                --paper-input-container-input: {
                    color: #101214;
                }
                --paper-input-container-underline: {
                    display: none;
                    border-bottom-color: var(--divider-color)!important;
                }
                --paper-input-container-input_-_top: 6px !important;
                --paper-input-container-input_-_left: 16px !important;
                --paper-input-container-input_-_line-height: 24px !important;
                --paper-input-container-label-floating_-_top: 10px !important;
                --paper-input-container-label-floating_-_font-size: 16px !important;
                --paper-input-container-label-floating_-_color: var(--secondary-color) !important;
                --paper-input-container-label-floating_-_left: 4px !important;
                --paper-input-container-label_-_padding: 0px 16px !important;
                --new-padding:0px 10px;
                --paper-input-container_-_min-height: 50px;
                --paper-input-container-label: {
                    font-family: SF Pro Display;
                    color: var(--secondary-color) !important;
                }
                --paper-input-container-underline-focus_-_top: 2px !important;
                --paper-input-container-label_-_font-size: 16px !important;
                --paper-font-subhead_-_font-size: 13px !important;
                --paper-input-container-underline-disabled: {
                    display: none;//added by satya to remove underline when disabled
                  }
            }
            .dateFieldStyle{
                --new-paddding: 0px 16px !important;
                --paper-input-container-label_-_padding: 0px 16px;
                --paper-input-container_-_min-height: 50px;
                --oe-label-mixin_-_color: #636C75;
                --paper-input-container-label-floating_-_left: 6px !important;
                --paper-input-container-label-floating_-_color: #636C75 !important;
                --paper-input-container-label-floating_-_top:  4px !important;
                --paper-input-container-input_-_top: 0px !important;
                --paper-input-container-input_-_left: 16px !important;
                --paper-input-container-label_-_left: 16px !important;
                --paper-input-container-label: {
                    font-family: SF Pro Display;
                    color: var(--secondary-color) !important;
                }
                --paper-input-container-underline-focus_-_top: 2px !important;
            }
            .currencyFieldStyle {
                --iron-icon-newheight: 32px;
                --iron-icon-newwidth: 32px;
                --new-padding: 0px 10px 10px 10px;
                --errorclass-padding-top: 2px;
                background: #F4F5F7;
                height: 56px;
                --paper-input-container-input_-_top: 0px;
                  border-radius: 0px;
                --paper-input-container-label-floating_-_left: 44px;
                --paper-input-container-label-floating_-_font-size: 16px!important;
                --paper-input-container-label-floating_-_top: 14px;
                --paper-input-container-label-floating_-_color: var(--secondary-color) !important;
                --paper-input-container-input_-_left: 0px;
                --paper-input-container-label_-_top: -5px !important;
                --paper-input-container-label_-_color: var(--secondary-color) !important;
            }
            .margin-0 {
                margin: 0px !important;
            }
            .margin-8 {
                margin: 8px !important;
            }
            .margin-16 {
                margin: 16px !important;
            }
            .margin-24 {
                margin: 24px !important;
            }
            .margin-32 {
                margin: 32px !important;
            }
            .margin-t-8 {
                margin-top: 8px !important;
            }
            .margin-t-16 {
                margin-top: 16px !important;
            }
            .margin-b-16 {
                margin-bottom: 16px !important;
            }
            .margin-tb-16 {
                margin-bottom: 16px !important;
                margin-top: 16px !important;
            }
            .margin-l-16 {
                margin-left: 16px !important;
            }
            .margin-t-24 {
                margin-top: 24px !important;
            }
            .margin-b-24 {
                margin-bottom: 24px !important;
            }
            .margin-l-24 {
                margin-left: 24px !important;
            }
            .margin-r-32 {
                margin-right: 32px !important;
            }
            .margin-l-32 {
                margin-left: 32px !important;
            }
            .margin-t-32 {
                margin-top: 32px !important;
            }
            .margin-lr-32 {
                margin-left: 32px !important;
                margin-right: 32px !important;
            }
            .margin-t-48 {
                margin-top: 48px !important;
            }
            .padding-tb-16 {
                padding-top: 16px !important;
                padding-bottom: 16px !important;
            }
            .padding-t-16 {
                padding-top: 16px !important;
            }
            .padding-b-16 {
                padding-bottom: 16px !important;
            }
            .padding-l-16 {
                padding-lef: 16px !important;
            }
            .padding-t-24 {
                padding-top: 24px !important;
            }
            .padding-b-24 {
                padding-bottom: 24px !important;
            }
            .padding-b-32 {
                padding-bottom: 32px !important;
            }
            .padding-l-24 {
                padding-left: 24px !important;
            }
            .padding-l-32 {
                padding-left: 32px !important;
            }
            .padding-r-24 {
                padding-right: 24px !important;
            }
            .padding-r-32 {
                padding-right: 32px !important;
            }
            .padding-lr-24 {
                padding-left: 24px !important;
                padding-right: 24px !important;
            }
            .padding-lr-32 {
                padding-left: 32px !important;
                padding-right: 32px !important;
            }
            .padding-0 {
                padding: 0px !important;
            }
            .padding-8 {
                padding: 8px !important;
            }
            .padding-16 {
                padding: 16px !important;
            }
            .padding-24 {
                padding: 24px !important;
            }
            .padding-32 {
                padding: 32px !important;
            }
            .primary-medium-button {
                font-size: 16px;
                line-height: 21px;
                height:var(--medium-button-height);
                text-transform: var(--primary-medium-button-text-transform, none);
                font-family: var(--bold-font) !important;
                border: 1px solid var(--primary-medium-button-border, #003D4C);
                background: var(--primary-medium-button-background, #003D4C);
                color: var(--primary-medium-button-color, #FFFFFF);
                border-radius: 4px;
                width: auto;
                padding: var(--primary-medium-button-padding, 12px 16px);
                min-width: 95px;
                letter-spacing: 0.15px;
                margin: var(--primary-medium-button-margin, 0px);
            }
            .primary-small-button{
                font-size: 16px;
                line-height: 21px;
                height:var(--small-button-height);
                text-transform: var(--primary-small-button-transform, none);
                font-family: var(--bold-font) !important;
                border: 1px solid var(--primary-small-button-border, #003D4C);
                background: var(--primary-small-button-background, #003D4C);
                color: var(--primary-small-button-color, #FFFFFF);
                border-radius: 4px;
                width: auto;
                padding: var(--primary-small-button-padding, 8px 16px);
                min-width: 95px;
                letter-spacing: 0.15px;
                margin: var(--primary-small-button-margin, 0px);
            }
            paper-dialog.paper-modal-dialog{
                left: 30%;
                width : 40% !important;
                height: auto !important;;
                padding: 0px !important
            }
            paper-dialog.paper-modal-dialog .head-section {
                padding: 20px 20px 20px 42px !important;
                margin-top: 0px !important;
                border-bottom: 1px solid #DFE1E6;
            }
            paper-dialog.paper-modal-dialog .content-section {
                padding: 42px 20px 42px 42px !important;
                margin: 0px !important;
            }
            paper-dialog.paper-modal-dialog .title{
                font-style: normal;
                font-family: var(--bold-font) !important;
                font-size: 24px;
                line-height: 29px;
                letter-spacing: 0.0025em;
                color: #101214;
            }
            paper-dialog.paper-modal-dialog .close-icon{
                position: absolute;
                right: 20px;
                top: 20px;
                color: #00839B;
                --iron-icon-width: 32px;
                --iron-icon-height: 32px;
                cursor:pointer;
            }	
            .font-32 {
				      font-size:32px;
			      }	            
            .font-28 {
				      font-size:28px;
			      }
			.font-22 {
				font-size:22px;
			}
			oe-combo.SRCombo,oe-combo.SRComboFloat{
			--paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              background: #F4F5F7;
              --paper-input-container-label_-_padding-left: 15px !important;
              --paper-input-container-label_-_top: 0px !important;
              --new-padding:0px 10px;
              --errorclass-padding-top:1vh;
              --paper-input-container-input_-_top: 0px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 8px !important;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 4px !important;
              border-radius: 5px;
              --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%;
                /*margin-top:22px!important;*/
                padding-top:10px !important;
                background: #00839B;
               }
               --paper-input-container-underline-disabled: {
                display: none;//added by satya to remove underline when disabled
              }
			}
			oe-input.SRInput,oe-input.SRInputFloat{
				  background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              --errorclass-padding-top:3vh;
              --paper-input-container-input_-_top: 0px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 8px !important;
			  --paper-input-container-label_-_top: -5px;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:15px !important;
                background: #00839B;
               }
			   }
			   oe-combo.SRComboFloats{
			--paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              background: #F4F5F7;
              --paper-input-container-label_-_padding-left: 15px !important;
              --paper-input-container-label_-_top: 0px !important;
              --new-padding:0px 10px;
              --errorclass-padding-top:1vh;
              --paper-input-container-input_-_top: 5px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 10px !important;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 4px !important;
              border-radius: 5px;
              --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%;
                /*margin-top:22px!important;*/
                padding-top:10px !important;
                background: #00839B;
               }
			}
			oe-input.SRInputFloats{
				  background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              --errorclass-padding-top:2vh;
              --paper-input-container-input_-_top: 8px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 10px !important;
			  --paper-input-container-label_-_top: -5px;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:15px !important;
                background: #00839B;
               }
			   }
			   .CardspapercardStyle {
				    padding: 2% 3%;
					margin-bottom: 2%;
		
		}
		oe-combo.SRCardsCombo{
			--paper-input-container_-_height: 40px !important;
              --paper-input-container_-_min-height: 40px !important;
              background: #F4F5F7;
              --paper-input-container-label_-_padding-left: 15px !important;
              --paper-input-container-label_-_top: 0px !important;
              --new-padding:7px 10px;
              --errorclass-padding-top:1vh;
              --paper-input-container-input_-_top: 0px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 5px !important;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 4px !important;
              border-radius: 5px;
              --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%;
                /*margin-top:22px!important;*/
                padding-top:0px !important;
                background: #00839B;
               }
			    --paper-input-container-underline-focus_-_display: none;
			}
			oe-input.SRCardsInput{
				  background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 40px !important;
              --paper-input-container_-_min-height: 40px !important;
              --errorclass-padding-top:3vh;
              --paper-input-container-input_-_top: 8px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 15px !important;
			  --paper-input-container-label_-_top: 5px;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:0px !important;
                background: #00839B;
               }
			   --paper-input-container-underline-focus_-_display: none;
			   }
			   .SRCardsBelowCard{
			       padding: 4% 5%;
				   }
				.SRCardtopPaper{
					    margin: 2% 3%;
				}
				.remarksInputStyle{
					--oe-label-mixin_-_color: var(--secondary-color) !important;
				--errorclass-padding-top:3vh;
				--paper-input-container_-_height:56px !important;
				--paper-input-container_-_background:#F4F5F7 !important;
				--paper-input-container-label-floating_-_top: 15px !important;
				--paper-input-container-input_-_top: 15px !important;
				--paper-input-container_-_width:100% !important;
				--paper-input-container_-_border-radius:4px !important;
				--paper-input-container-underline: {
				  display: none;
				  }
				  --paper-input-container-underline-focus: {
							  display: none;
					  }
				  --paper-input-container-underline-disabled: {
						  display: none;
					  }
					  --paper-input-container-underline-focus: {
						position: absolute;
						left: 0%;
						right: 0%;
						top: -5px;  /* For error msg */
						bottom: 0%;  
						padding-top:20px !important; 
					   }
				}
        oe-input { 
            --oe-required-mixin: { 
            display:inherit; 
            } 
        }
        oe-combo { 
            --oe-required-mixin: { 
            display:inherit; 
            } 
        }
        oe-date { 
            --oe-required-mixin: { 
            display:inherit; 
            } 
        }
        oe-radio-group { 
            --oe-required-mixin: { 
            display:inherit; 
            } 
        }
        oe-decimal{ 
            --oe-required-mixin: { 
            display:inherit; 
            } 
        }
        oe-decimal.decimalFieldStyle {
            --paper-input-container: {
                background: #F4F5F7!important;
                border-radius: 3px!important;
				padding: 0px!important;	
                font-size: 16px !important;
            }
            --paper-input-container_-_height:54px!important;
            --paper-input-container_-_width:100%!important;

            --paper-input-container-label: {
                padding: 16px 0px 6px 15px;
                font-family: SF Pro Display;
                color: var(--label-color, #f4f5f7);
            }

            --paper-input-container-input: {
                color: #101214;
            }

            --paper-input-container-label-floating: {
                top: 28px;
                left: 10px;
            }

            --paper-input-container-focus-color: #00839B;

            --paper-input-container-underline: {
                display: none;
                border-bottom-color: var(--divider-color);
            }
            
            --paper-font-caption: {
                display: inline-block; /* For error msg */
                font-size:12px!important; /* For error msg */
            }
            
            --errorclass-font-size: 12px!important;  /* For error msg */
            --errorclass-padding-top: 10px;  /* For error msg */

            --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;
                bottom: 0%;                   
                background: #00839B;
                margin-top: 16px;
                }

            --oe-label-mixin: {
                padding-left: 16px;
                font-size: 16px!important;
            }


            --paper-input-error_-_margin-top : 20px;
            --paper-input-container-underline-focus: {
                display: none;
            }
            --paper-input-container-underline-disabled: {
                display: none;
            }
            --paper-input-container-shared-input-style_-_padding: 8px 0px 0px 16px !important;
        }

        .divider{
            border-bottom: 1px solid #DFE1E6;
        }
        .row-border-bottom {
            border-bottom: 0.1px solid #DFE1E6;
        }
        .iron-icon24{
            --iron-icon-width: 24px;
            --iron-icon-height: 24px;
        }
        .iron-icon29{
            --iron-icon-width: 29.29px;
            --iron-icon-height: 29.29px;
        }
        .iron-icon39{
            --iron-icon-width: 39.05px;
            --iron-icon-height: 39.05px;
        }
        .iron-icon16{
            --iron-icon-width: 16px;
            --iron-icon-height: 16px;
        }
        .break-word{
            word-wrap: break-word;
        }

        .status-button{
            font-family: SF Pro Display;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            letter-spacing: 0.4px;
            border-radius: 10px;
            padding: 2px 8px;
            min-height: 20px;
            text-align: center;
            width: fit-content !important;
            display: flex;
            align-items: center;
        }
        .status-button.pending-approval{
            color: #A57B1E;
            background: #F9E7BF;
        }
        .status-button.requires-repair{
            color: #B75802;
            background: #FFE4CA;
        }
        .status-button.ready-for-release{
            color: #2F6B97;
            background: #EAF0F5;
        }
        .status-button.rejected{
            color: #DA2128;
            background: #F8D3D4;
        }
        .status-button.success{
            color: #119365;
            background: #DDF8EE;
        }
        .status-button.partially-successful{
            color: #A57B1E;
            background: #F9E7BF;
        }
        .status-button.processing{
            color: #2F6B97;
            background: #EAF0F5;
        }
        .status-button.failed{
            color: #DA2128;
            background: #F8D3D4;
        }
        .status-button.active{
            color: #119365;
            background: #DDF8EE;
        }
        .status-button.blueStatus{
            color: #292E33;
            background: #EAF0F5;
        }
        .status-button.inactive{
            color: #DA2128;
            background: #F8D3D4;
        }
        .filterDateField{
            --paper-input-container: {
                min-height:40px!important;
                height:40px!important;
                background:#F4F5F7!important;
                background-color:#F4F5F7!important;
            }
            
            --paper-input-container-input_-_font-size: 14px;
            --paper-input-container-input_-_font-weight: 500;
            --paper-input-container-input_-_left: 12px !important;
            --paper-input-container-input_-_top:4px!important;
            --paper-input-container-label-floating_-_top: 20px;
            --paper-input-container-label_-_color: var(--secondary-color) !important;
            --paper-input-container-label_-_padding: 3px 10px !important;
            
            --paper-input-container-label: {
                font-family: SF Pro Display;
                padding: 3px 10px !important;
                color: var(--secondary-color) !important;
                }

                --iron-icon-datenewheight: 16px;
                --iron-icon-datenewwidth: 16px;
                --new-paddding: 10px;
                --oe-label-mixin_-_font-size: 14px!important;
                --oe-label-mixin_-_padding-left: 12px;
                --oe-label-mixin_-_color: var(--secondary-color) !important;
                --paper-input-container-shared-input-style_-_font-size: 14px;
                --paper-input-container-input_-_color: var(--secondary-color) !important;
            }

            .filterDecimalField{
                --paper-input-container: {
                    min-height:40px!important;
                    height:40px!important;
                    background:#F4F5F7!important;
                    background-color:#F4F5F7!important;
                    padding: 0px !important;
                }
                
                --paper-input-container-input_-_font-size: 14px;
                --paper-input-container-input_-_font-weight: 500;
                --paper-input-container-input_-_left: 13px !important;
                --paper-input-container-input_-_top: 8px!important;
                --paper-input-container-label-floating_-_top: 20px;
                
                --paper-input-container-label: {
                    font-family: SF Pro Display;
                    padding: 8px 10px !important;
                    color: var(--secondary-color) !important;
                }

                --iron-icon-datenewheight: 16px;
                --iron-icon-datenewwidth: 16px;
                --new-paddding: 10px;
                --oe-label-mixin_-_font-size: 14px!important;
                --oe-label-mixin_-_padding-left: 12px;
                --oe-label-mixin_-_color: var(--secondary-color) !important;
                --paper-input-container-shared-input-style_-_font-size: 14px;
                --paper-input-container-input_-_color: var(--secondary-color) !important;
                }

                .inputfieldStyle, .inputFieldStyle {
                    --paper-input-container: {
                        font-family: SF Pro Display!important;
                        font: SF Pro Display!important;
                        padding: 0px 0px !important;
                        border-width: 0px;
                        box-sizing: border-box;
                        background-color: #F4F5F7;
                        background: #F4F5F7;
                        height: 50px;
                        min-height: 50px;
                    }

                    --paper-input-container-label: {
                        padding: 0px;
                        font-family: SF Pro Display;
                        color: var(--label-color, #f4f5f7);
                    }

                    --paper-input-container-input: {
                        top: 6px !important;
                        left: 16px !important;
                    }

                    --paper-input-container-focus-color: #00839B;
        
                    --paper-font-caption: {
                        display: inline-block;
                        font-size:12px!important;
                    }
                    --paper-input-container-label_-_padding-left:20px;
                    --errorclass-font-size: 12px!important;
                    --errorclass-padding-top: 10px; 
    
                    --oe-label-mixin: {
                        padding-left: 16px;
                        font-size: 16px !important;
                        color: var(--secondary-color) !important;
                    }
                    --paper-input-container-label-floating_-_top: 7px;
                    --paper-input-container-label-floating_-_left: 3px;
                    --paper-input-container-underline-focus_-_top: 2px !important;
                    --paper-input-container-underline-focus_-_margin-top: 10px !important;
                }
                
                
                
                
                .SRFormBack{
			border: 1px solid #003D4C !important;
			height: 45px !important;
			width: -webkit-fill-available !important; 
			--paper-button_-_min-width: 63px !important;
			margin-right: 14px !important;
		}
		.SRFormProceed{
			width: -webkit-fill-available !important;
			height: 45px !important;
			max-width: 95% !important;
			margin-left: 1px !important;
		}
		.SRBackProceedCard{
			padding: 35px;
			margin-top: 2px !important;
		}
		.SRPreviewButtonCard{
			padding: 30px;
			margin-top: 2px !important; 
		}
		.SRPreviewBack{
			border: 1px solid #003D4C !important;
			font-weight:bold !important;
			min-width: 90% !important;
			min-height: 45px !important;
		}
        .SRPreviewProceed{
			min-width: 180px;
			height: 40px;
			min-height: 45px;
		}
		.SRPreviewMargin{
			margin:2% 4%;
		}
		.SRSuccessMargin{
			margin:1% 4%;
		}
		.SRNoteMargin{
			margin-top: 3%;
		}
		.SRPreviewDetailsMargin{
			margin: 15px 0px;
		}
		oe-decimal.SRDecimalFloat{
				  background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              --errorclass-padding-top:2vh;
              --paper-input-container-input_-_top: 8px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 10px !important;
			  --paper-input-container-label_-_top: -5px;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:15px !important;
                background: #00839B;
               }
			   }
			   oe-date.SRDateFloat{
			  --new-paddding: 0em 1em;
			  background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              --errorclass-padding-top:2vh;
              --paper-input-container-input_-_top: 8px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 15px !important;
			  --paper-input-container-label_-_top: -5px;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:15px !important;
                background: #00839B;
               }
			   }
			   .SRCardsProceed{
			width: -webkit-fill-available !important;
			height: 45px !important; 
			margin-left: 1px !important;
		}
		oe-date.SRCardsDateFloat{
			--paper-input-container_-_min-height: 40px;
		--paper-input-container_-_height: 40px;
		--paper-input-container_-_border-radius: 5px;
		--paper-input-container-label_-_top: 0px;
		--paper-input-container-label_-_padding-left: 15px;
		--oe-label-mixin_-_color: #636C75; 
		--paper-input-container-underline-focus_-_display: none;
		--paper-input-container-input_-_top: 5px !important;
		}
		 oe-date.SRDateField {
				--paper-input-container_-_min-height: 55px;
				--paper-input-container_-_height: 55px;
				--paper-input-container_-_border-radius: 5px;
				--paper-input-container-label_-_top: 0px;
				--paper-input-container-label_-_padding-left: 0px;
				--oe-label-mixin_-_color: #636C75;
				--paper-input-container-underline-focus_-_display: none;
				--paper-input-container-input_-_top: 6px !important;
        --paper-input-container-input_-_left: 16px !important;
				--paper-input-container-label-floating_-_top: 12px !important;
                --paper-input-container-label_-_left: 16px !important;
                --paper-input-container-underline-disabled: {
                        display: none;
                }
            }
			.SRCardsListDate{
			--iron-icon-datenewheight: 25px;
			--iron-icon-datenewwidth: 30px;
			--new-paddding: 5px;
			}
			oe-date.SRLoanDateField {
				--paper-input-container_-_min-height: 50px;
				--paper-input-container_-_height: 50px;
				--paper-input-container_-_border-radius: 5px;
				--paper-input-container-label_-_top: 0px;
				--paper-input-container-label_-_padding-left: 15px;
				--oe-label-mixin_-_color: #636C75;
				--paper-input-container-underline-focus_-_display: none;
				--paper-input-container-input_-_top: 10px !important;
				--paper-input-container-label-floating_-_top: 12px !important;
                --paper-input-container-underline-disabled: {
                        display: none;
                }
            }
			oe-input.SRCurrencyAmt{
				  background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              --errorclass-padding-top:4vh;
              --paper-input-container-input_-_top: 16px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 10px !important;
			  --paper-input-container-label_-_top: 10px !important;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:15px !important;
                background: #00839B;
               } 
				--paper-input-container-underline-focus_-_display: none;
			   }


				.accountsel{
					--paper-input-container-label_-_left: 16px !important;
				}


				.SRdateIronClass{
							  --iron-icon-datenewheight: 25px;
					--iron-icon-datenewwidth: 45px;
					--new-paddding: 0px;
				} 
				.valuePadding{
				  padding-top:5px !important;
				  padding-bottom:5px !important;
				}
				.SRReqStsMargin{
				margin:2%;
				}
		.listLabelPadding{
		    padding: 1% 2%;
		}
		.listValuePadding{
		    padding: 0% 2%;
		}

        .filterListField {
			--paper-input-container-suffix: {
                padding: 4px 10px 0 0 !important;
            }
		    --paper-input-container-label-floating: {
                top: 28px;
                left: 10px;
            }
            --paper-input-container-focus-color: #00839B;
            --paper-input-container-underline: {
                display: none;
                border-bottom-color: var(--divider-color);
            }
            --paper-font-caption: {
                display: none;
            }

            --oe-label-mixin: {
                padding-left: 15px;
                font-size: 16px!important;
            }

            --paper-item:{
                font-size:13px;
            }

            --paper-input-container: {
                background: #F4F5F7 !important;
                background-color: #F4F5F7 !important;
                border-radius: 3px;
                padding: 0px !important;
                font-size: 13px;
                height: 40px;    
            }
            --paper-input-container-shared-input-style_-_font-size: 13px;
            --paper-input-container-input: {
                top: 0px!important;
                left: 10px!important;
                color: var(--secondary-color) !important;
            }
            --paper-input-container-label: {
                padding: 0px 0px 0px 13px;
                font-family: SF Pro Display;
                font-size:13px;
            }
            --paper-input-container-underline: {
                display: none;
            }
            --paper-input-container-underline-focus: {
                display: none;
            }
            --paper-input-container-underline-disabled: {
                display: none;
            }
            --iron-icon-newheight: 23px;
            --iron-icon-newweight: 23px;
            --new-padding: 10px 0px;
        }

        paper-checkbox.selection-checkbox {
            --paper-checkbox-checked-color: var(--brand-color) !important;
        }
        
        .chips-option-unSelected.chips-option-selected{
            background-color: var(--brand-color) !important;
        }

        paper-toggle-button {
            --paper-toggle-button-checked-bar_-_background-color: var(--brand-color) !important;
        }
		.BUMargin{
		margin: 3% 4%;
		}
		.BUPadding{
			    padding: 1% 2% 2% 2%;
		}
		.successSummary{
			 font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0025em;
                color: #101214;
				font-family: 'SF-Pro-Display-Medium';
		}
		.sub-heading-status
{
    font-size: 10px;
    line-height:12px;
    color: #636C75;
    font-weight:700;
    border-bottom: 2px solid #F2F9FB;
    text-transform:uppercase;
}
.sub-desc-status{
    font-size:13px;
    color:#101214;
    font-weight: 600;
    line-height:24px;    
}



.approveButton{
                font-weight: 700;
                font-size: 16px;
                border: 1px solid;
                border-radius: 4px;
                color: #4CA585;
                height: 37px;
                text-transform: initial;
                width: auto;
                font-family: 'SF Pro Display';
                padding: 15px;
                margin-right: 1.5% !important;
        }
.rejectButton{
        font-weight: 700;
        font-size: 16px;
        border: 1px solid;
        border-radius: 4px;
        color: #DA2128;
        height: 37px;
        text-transform: initial;
        width: auto;
        font-family: 'SF Pro Display';  
        padding: 15px;
        margin-right:  1.5% !important;
}
.recallButton{
        font-weight: 700;
        font-size: 16px;
        border: 1px solid;
        border-radius: 4px;
        color: #DA2128;
        height: 37px;
        text-transform: initial;
        width: auto;
        font-family: 'SF Pro Display';  
        padding: 15px;
        margin-right:  1.5% !important;
}
.viewDetailsButton{
        background: #003D4C;
        border-radius: 4px;
        color: #F9FCFF;
        font-weight: 700;
        font-size: 16px;
        height: 37px;
        text-transform: initial;
        width: auto;
        font-family: 'SF Pro Display';
        padding: 15px;
        margin-right: 1.5% !important;
}
.repairButton{
    color: #7C4182;
    font-weight: 700;
    font-size: 16px;
    border: 1px solid;
    border-radius: 4px;
    height: 37px;
    text-transform: initial;
    width: auto;
    padding: 15px;
    font-family: 'SF Pro Display';
    margin-right: 1.5% !important;
}
oe-input.filterInput{
    background: #F4F5F7;
              border-radius: 5px;
              --paper-input-container_-_height: 40px !important;
              --paper-input-container_-_min-height: 40px !important;
              --errorclass-padding-top:3vh;
              --paper-input-container-input_-_top: 8px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 15px !important;
			  --paper-input-container-label_-_top: 5px;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 16px !important;
              --oe-label-mixin_-_color: #636C75;
              --oe-label-mixin_-_font-size: 13px !important;
              --paper-input-container-label_-_left: 16px !important;
			   --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: -5px;  /* For error msg */
                bottom: 0%; 
                padding-top:0px !important;
                background: #00839B;
               }
			   --paper-input-container-underline-focus_-_display: none;
              }
              
            .ft-title,.headingStyle,.previewHeadingStyle,.largerHeadingStyle {
                font-family: var(--bold-font) !important;
                font-size: var(--ft-title-font-size, 31px) !important;
                letter-spacing: 0.0025em;
            }
             .ft-subtitle,.secondaryHeadingStyle { 
                font-family: var(--bold-font) !important;
                font-size: var(--ft-subtitle-font-size, 19px) !important; 
                letter-spacing: 0.0025em;
            }
            .border-bottom {
                border-bottom: 1px solid #f6f8f9;
            }
            .white-background {
                background: #fff !important;
            }
			  .margin-t-b-2{
				  margin-top:2%;
				  margin-bottom:2%;
			  }
			  .advFilterIcon{
				      padding-top: 2%;
			  }
			  .advFilterMargin{
				      margin-top: 20%;
			  }

		 .header_cif_div {
    width: 42%;
--paper-input-container_-_min-height: 45px !important;
--new-padding: 10px 10px 10px 0px;
margin-top: 1.5%;
--paper-input-container-label-floating_-_font-size: 15px;
--paper-input-minheight:10px;
--paper-input-shared-padding:1.2% 0%;
  }
  
            .title-text{
                font-family: 'SF Pro Display';
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                color: var(--secondary-color) !important;
            }
            
            .value-text {
                font-family: var(--bold-font);
                font-size: 16px;
                line-height: 20px;
                color: #101214;
                overflow-wrap: anywhere;
            }
            .font-30Heading{ 
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 700;
                font-size: 30px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0025em;
                color: #101214;
				font-family: 'SF-Pro-Display-Medium';
              } 
			  .font-18Heading{ 
                font-family: 'SF Pro Display';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0025em;
                color: #101214;
				font-family: 'SF-Pro-Display-Medium';
              }
				.textFontBold{
					font-family: 'SF-Pro-Display-Medium';
					    font-weight: 700;
				}	
				.addBenInput{
					  background: #F4F5F7;
					border-radius: 5px;
					--paper-input-container_-_height: 50px !important;
					--paper-input-container_-_min-height: 50px !important;
					--errorclass-padding-top: 2vh;
					--paper-input-container-input_-_top: 8px !important;
					--paper-input-container-input_-_left: 15px !important;
					--paper-input-container-input_-_line-height: 24px !important;
					--paper-input-container-label-floating_-_top: 15px !important;
					--paper-input-container-label_-_top: -5px;
					--paper-input-container-label-floating_-_font-size: 16px !important;
					--paper-input-container-label-floating_-_color: #636C75;
					--paper-input-container-label-floating_-_left: 5px !important;
					--oe-label-mixin_-_color: #636C75;
					--paper-input-container-label_-_left: 5px !important;
					--paper-input-container-underline-focus_-_position: absolute;
					--paper-input-container-underline-focus_-_left: 0%;
					--paper-input-container-underline-focus_-_right: 0%;
					--paper-input-container-underline-focus_-_top: 10px;
					--paper-input-container-underline-focus_-_bottom: 0%;
					--paper-input-container-underline-focus_-_padding-top: -5px !important;
					--paper-input-container-underline-focus_-_background: #00839B;
					--paper-input-container-underline-focus_-_display: initial;
					--paper-input-container-underline-focus_-_margin-top: initial;
					--paper-input-container-underline-focus_-_border-bottom: initial;
					}
					.benNameInput {
					/*--oe-label-mixin_-_padding-left: 0px;*/
					  background: #F4F5F7;
					  border-radius: 5px;
					  --paper-input-container_-_height: 50px !important;
					  --paper-input-container_-_min-height: 50px !important;
					  --errorclass-padding-top:2vh;
					  --paper-input-container-input_-_top: 0px !important;
					  --paper-input-container-input_-_left: 16px !important;
					  --paper-input-container-input_-_line-height: 24px !important;
					  --paper-input-container-label-floating_-_top: 10px !important;
					  --paper-input-container-label_-_top: -5px;
					  --paper-input-container-label-floating_-_font-size: 16px !important;
					  --paper-input-container-label-floating_-_color: #636C75;
					  --paper-input-container-label-floating_-_left: 5px !important;
					  --oe-label-mixin_-_color: #636C75;
					  --paper-input-container-label_-_left: 0px !important;
					   --paper-input-container-underline-focus: {
						position: absolute;
						left: 0%;
						right: 0%;
						top: 11px;  /* For error msg */
						bottom: 0%; 
						padding-top:0px !important;
						background: #00839B;
					   } 
						}  
					.previewInfoStylebenef{
							font-family: 'SF Pro Display';
							font-style: normal;
							font-weight: 400;
							font-size: 13px;
							line-height: 24px;
							color: #636C75;
					}
					.previewValueStylebenef{
							font-family: 'SF Pro Display';
							font-style: normal;
							font-weight: 700;
							font-size: 13px;
							line-height: 24px; 
							color: #101214;					
							font-family: 'SF-Pro-Display-Medium'; 
					}
					.benefMargin{
						margin:2% 3%;						
					}
					.accordianInfo{
					font-size:13px; 
					font-family: 'SF-Pro-Display-Medium'; 
					color: #636C75;
					}
					.accordianValue{
					font-size:13px; 
					font-family: 'SF-Pro-Display-Medium';  
					font-weight: 700;
					}
					.text-transform-unset{
						text-transform: unset;
					}
					.BenListingMargin{
						margin:4% 3% 4% 4%;
					}
					.margin-b-2{
						margin-bottom:2%;
					}
                    .wordWrap{
                        overflow-wrap: anywhere;
                    }

            .back-button {
                display: flex;
                align-items: center;
            }

            .back-button iron-icon {
                color: var(--brand-color) !important;
                width: 18px; 
                height: 18px;
                margin-right: 4px;
                cursor: pointer;
            }
            .back-button .back-button-text {
                color: var(--brand-color) !important;
                font-family: 'SF Pro Display';
                font-style: normal;
                font-size: 15px;
                line-height: 24px;
                letter-spacing: 0.15px;
                cursor: pointer;
                font-family: var(--bold-font);
            }
          
            .page-title {
                font-size: 32px;
            }

            paper-radio-button.iron-selected {
                --paper-radio-button-checked-color: var(--brand-color) !important;
            }
		
		
		
		/* Satya Styles */
		
		/* Accounts nd Cards */
        .outstandingtabOuterClass{
			padding: 2%;
			margin: 1.8% 2%;
		   }
		.acct-tableaction-padding{
			padding:1% 1.5% 0.9% 2.2%;
		}
		.tbl-action-category-text{
			margin-bottom:2%;
		}
		.tbl-action-category-text-search{
			margin-bottom:0.3% !important;
		}
		.acct-paper-padding{
			padding: 2.6% 2.7% 1.25% 3.1%;
			margin:3.6%;
			margin-bottom:0.1% !important;
		}
		.acct-paper-innerpadding{
			padding-bottom: 3.8%;
		}
		.acct-det-acct-name{
		font-size:26px;
		font-weight:700;
		font-family: 'SF-Pro-Display-Medium';
		}
		.acct-det-acct-type{
		font-size:14px;
		font-weight:500;
		padding-right:0.9%;
		}
		.acct-det-acct-status{
		font-size:28px;
		font-weight:700
		}
		.acct-det-innerclass1{
			padding-top:4.6%;
			padding-bottom:3.6%;
			border-top:2px solid #DFE1E6;
			margin-right: 12.5%;
		}
		.acct-det-fieldName{
			font-weight:500;
			font-size: 13px;
			letter-spacing: 0.15px;
			color: #636c75;
		}
		.acct-det-fieldValue{
			font-weight:700;
			font-size: 15px;
			color: #101214;
		}
		.acct-det-fieldValue2{
			font-weight:700;
			font-size: 18px;
			color: #101214;
		}
		.acct-paper-innerpadding2{
			background:aliceblue;
			padding: 2.4% 1% 2.4% 3.1%;
			height: fit-content;
			width: 40.8%;
			margin-right:0px;
		}
		.acct-det-fieldName2{
			font-weight:500;
			font-size: 15px;
			letter-spacing: 0.15px;
			color: #636c75;
		}
		.acct-det-fieldNameVlaue{
			padding:0.8%;
		}
		.acct-nbb-table-padding{
			padding:1.44%;
			padding-top: 2.2%;
		}
		.acct-list-header-class{
			font-size:10px;
			font-weight:700;
			color:#636c75;
			text-transform:uppercase;
		}
		.acct-list-value-class{
			font-size:13px;
			font-weight:700;
			color:#101214;
            cursor:pointer;
			
		}
		.loan-det-innerclass1{
			padding-top:4%;
			padding-bottom:1.6%;
			margin-right: 8.5%;
		}
		.loan-paper-innerpadding2{
			background: aliceblue;
    padding: 1.4% 1% 1% 1.1%;
    height: fit-content;
    width: 40.8%;
    margin-right: 0px;
		}
		.loan-paper-innerpadding{
			padding-bottom: 3%;
		}
		.card-tableaction-padding{
			padding:1% 1.5% 0.9% 2.2%;
		}
		iron-collapse .cards-details-container{
            background: #F9FCFF;
            border-left: 2px solid teal !important;
            width:100%;
            padding: 1.5%;
            
        } 
		.accor-details-class{
			padding-left:4.4%;
		}
		.accor-sub-heading-status{
        font-size: 10px;
        line-height:12px;
        color: #636C75;
        font-weight:700;
        border-bottom: 2px solid #F2F9FB;
        text-transform:uppercase;
    }
    .accor-sub-desc-status{
        font-size:13px;
        color:#101214;
        font-weight: 600;
        line-height:24px;
        
    }
	.acct-credit-heading {
    color: #101214;
    font-weight: 700;
    font-size: 30px;
    line-height: 40.57px;
    margin: 1.8% 0% 1.9% 0%;
}
.card-footer-class{
	background-color: #eff8fa;
    padding: 1.5% 2%;
}
.card-preview-details-class{
	font-size: 31px;
    letter-spacing: 0.15px;
	font-weight:700;
}
.autoSettlemarginclass{
	margin-left: 3%;
}
.autoSettle-preview-details-fieldName{
	font-size:13px;
	font-weight:500;
	color:#636c75;
	margin:3%;
}
.autoSettle-preview-details-fieldValue{
	font-size:13px;
	font-weight:700;
	color: #101214;
}
.autoSettle-app-sltr-class{
	--new-app-selector-width:98%;
	--new-app-selector-padding:1% 1% 3.7% 1%; 
	--new-app-selector-margin-left: 17px;
}
.searchheight{
	--oe-label-mixin_-_padding-left:0px !important;
}
.card-credit-heading {
    color: #101214;
    font-weight: 700;
    font-size: 31px;
    line-height: 40.57px;
    margin: 1.5% 0% 2.2% 0%;
}
.mediumLargeHeadingStyle{
                font-family: 'SF-Pro-Display-Medium';
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 24px;
                display: flex;
                align-items: center;
                letter-spacing: 0.0025em;
                color: #101214;
				
              }
.credit-card-fieldName {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	font-family: 'SF Pro Display';
	color: #636c75;
}
 .credit-card-fieldValue {
	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	font-family: 'SF Pro Display';
	color: #101214;
}  
.debit_acct_margin{
          margin-left:3%
      }
/* Accounts and Cards */
    
	
/* Approvals */
.header-background{
				background: #FCFCFD;
			}
 .appr-status-approval-css{
                width:80%;
            }
.initiate-status-approval-css{
                width:50%;
            }
	.approval-css{
		width:30%;
	
	}
			.main-tabs paper-tabs{
                height: 70px;
                
                }
				
			.cmptxnMargin{
				margin:2% 3.8%;
			}
			paper-tabs paper-tab {
                    text-align:left;
                    float:left;
                    margin:0px;
                    --brand-color:#00839B;
					font-size: 18px;
					font-weight: 700;
					font-family: 'SF-Pro-Display-Medium'; !important;
                }
				 .header-field-small-bold{
                font-size:13px;
                font-weight:700;
                color: #636c75;
                letter-spacing: 0.0025em;
                font-family: 'SF-Pro-Display-Medium'; !important;
            }
			
            .header-field-small{
                font-size:13px;
                color: #636c75;
                letter-spacing: 0.0025em;
            }
			.header-field-small-400{
                font-size:13px;
				font-weight:400;
                color: #636c75;
                letter-spacing: 0.0025em;
            }
            .header-value-small-bold{
                font-size:13px;
                font-weight:700;
                color: #101214;
                letter-spacing: 0.0025em;
                font-family: 'SF-Pro-Display-Medium'; !important;
            }
			.header-field-xsmall{
                font-size:12px;
                color: #636c75;
                letter-spacing: 0.0025em;
				line-height: 17px;
            }
            .header-value-xsmall-700{
                font-size:12px;
                font-weight:700;
                color: #101214;
                letter-spacing: 0.0025em;
				line-height: 17px;
				font-family: 'SF-Pro-Display-Medium';
            }
			.header-value-large-700{
				font-size:15px;
                font-weight:700;
                color: #101214;
                letter-spacing: 0.0025em;
				line-height: 17px;
				font-family: 'SF-Pro-Display-Medium';
			}
			.header-value-large{
				font-size:15px;
                color: #101214;
                letter-spacing: 0.0025em;
				line-height: 24px;
			}
			.header-value-xlarge-700{
				font-size:17px;
                font-weight:700;
                color: #101214;
                letter-spacing: 0.0025em;
				line-height: 17px;
				font-family: 'SF-Pro-Display-Medium';
			}
			.header-field-large{
				font-size:15px;
                color: #636c75;
                letter-spacing: 0.0025em;
				line-height: 17px;
			}
			.credit-heading{
          color: #101214;
          font-weight: 700;
          font-size: 31px;
          font-family: 'SF-Pro-Display-Medium'; !important;
        }
		.papertab-marginClass{
			
			margin-left:3.8%;
		}
		 .header_cif_div {
    width: 42%;
--paper-input-container_-_min-height: 45px !important;
--new-padding: 10px 10px 10px 0px;
margin-top: 1.5%;
--paper-input-container-label-floating_-_font-size: 15px;
--paper-input-minheight:10px;
--paper-input-shared-padding:1.2% 0%;
  }
.goBack_apprvl{
        color:#04859C;
        padding-left:0.3%;
      }
      .goBackIcon{
        color:#04859C;
      }
      .previewMsgAppr{
        padding-top: 0.4%;
      }
      .appr_back_previewClass{
        margin-bottom:3%;
        margin-top:2.7%;
      }
 .goBackIcon_Initiate{
            color:#00839B;
            padding-right: 1%;
          }	
.goBack_Initiate{
            color:#00839B;
            width: 16%;
          }	
                  .bottom-desktop-navigation  {
                    position: fixed;
                      width: 81.90%;
                      bottom: 0%;
                      z-index: 2;
                      left: 215px;
                } 
   .bottom-div-cards{
                    height: 60.29px;
                    background: #EFF8FA;
                    box-shadow: 0px 0px 2px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 10%);
                    display: flex;
                   
                  }	
.accordianColor{
                    color: #00839B;
                  }	
.menu-tabs-3-active .iron-icon-style-s, :host([dir="rtl"]) .menu-tabs-3-active .iron-icon-style-s{
            color: #003d4c;
        }	
.hyd_descrp{
		line-height: 22px;
    font-family: 'SF-Pro-Display-Regular';
    font-weight: 700;
	font-size:14px;
	color: #636c75;
	  }	
        .heading-div {
            height: 58px;
            line-height: 55px;
            background: #FCFCFD;
            margin-bottom: 3px;
            font-size: 19px;
            color: #101214;
            padding-left: 32px;
            letter-spacing: 0.0025em;
            font-family: var(--bold-font) !important;
        }
        
        .heading-div iron-icon {
            width: 24px;
            height: 24px;
            padding-right: 12px;
        }

        .currency {
            --iron-icon-width: 16px;
            --iron-icon-height: 16px;
        }

        .listing-table-container .listing-top-section {
            padding: 13px 24px 19px 24px;
        }

        .listing-table-container .inline-table-actions {
            display: flex;
            align-items: end;
            margin-bottom: 16px;
            margin-left: auto;
        }
      
        .listing-table-container .head-row{
            background: #FAFAFB;
            min-height: 34px;
            padding-left: 24px;
        }

        .listing-table-container .listing-items {
            border-bottom: 1px solid #f6f8f9;
        }
      
        .listing-table-container .list-item {
            min-height: 34px;
            padding-left: 24px;
        }
      
        .listing-table-container .openedItem-true {
            border-left: 2px solid #00839B !important;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.1);
            z-index: 1;
            background: #fff;
        }

        .listing-table-container .openedItem-true:hover {
            background: #fff;
        }

        .listing-table-container .last-remarks-container {
            padding: 16px 16px 0px;
        }
      
        .listing-table-container .detail-footer-buttons {
            display:flex;
            justify-content: flex-end;
            padding: 24px 0px 40px;
            column-gap: 16px;
        }
        
        .listing-table-container .search-box{
            --searchbox-margin-left : 0px !important;
            --searchbox-margin-right : 0px !important;
        }
      
        .listing-table-container .arrow-icon-div iron-icon{
            width: var(--iron-icon-width, 29.29px);
            height: var(--iron-icon-height, 29.29px);
            color: var(--brand-color) !important;
          }
      
        .listing-table-container .currency{
            --iron-icon-width: 16px;
            --iron-icon-height: 16px;
        }        
      
        .listing-table-container iron-collapse .details-container{
            background: #F9FCFF;
        }
      
        .listing-table-container .details-divider {
            margin: 0px 16px;
            border-bottom: 1px solid #DFE1E6;
        }

        .listing-table-container .details-container{
            padding: 0px 40px 0px 25px;
        }
      
        .listing-table-container iron-list {
            overflow-y: overlay !important;
        }

        .listing-table-container .column-value {
            overflow-wrap: anywhere;
            font-size: var(--a-font-size-s) !important;
        }
        .listing-table-container .column-heading {
            color: var(--secondary-color) !important;
            font-size: var(--a-font-size-xxm) !important;
            font-family: var(--bold-font) !important;
            text-transform: uppercase;
        }

        .secondary-medium-button {
            font-size: 16px;
            line-height: 21px;
            height:var(--medium-button-height);
            text-transform: var(--secondary-medium-button-text-transform, none);
            font-family: var(--bold-font) !important;
            border: 1px solid var(--secondary-medium-button-border, #003D4C);
            background: var(--secondary-medium-button-background, none);
            color: var(--secondary-medium-button-color, #003D4C);
            border-radius: 4px;
            width: auto;
            padding: var(--secondary-medium-button-padding, 12px 16px);
            font-family: 'SF Pro Display';
            min-width: var(--secondary-medium-button-min-width, 95px);
            letter-spacing: 0.15px;
            margin: var(--secondary-medium-button-margin, 0px);
        }
        .secondary-small-button{
            font-size: 16px;
            line-height: 21px;
            height:var(--small-button-height);
            text-transform: var(--secondary-small-button-transform, none);
            font-family: var(--bold-font) !important;
            border: 1px solid var(--secondary-small-button-border, #003D4C);
            background: var(--secondary-small-button-background, none);
            color: var(--secondary-small-button-color, #003D4C);
            border-radius: 4px;
            width: auto;
            padding: var(--secondary-small-button-padding, 8px 16px);
            font-family: 'SF Pro Display';
            min-width: 95px;
            letter-spacing: 0.15px;
            margin: var(--secondary-small-button-margin, 0px);
        }
        
        .link {
            text-decoration: underline;
            color: var(--brand-color);
            cursor: pointer;
        }
        .actionLink {
            text-decoration: none !important;
            color: var(--brand-color);
            cursor: pointer;
        }
.approveButtonBA{
                font-weight: 700;
                font-size: 16px;
                border: 1px solid;
                border-radius: 4px;
                color: #4CA585;
                height: 37px;
                text-transform: initial;
                width: 131px;
                font-family: 'SF Pro Display';
                padding: 15px;
                margin-right: 0.5vw !important;
        }
.rejectButtonBA{
        font-weight: 700;
        font-size: 16px;
        border: 1px solid;
        border-radius: 4px;
        color: #DA2128;
        height: 37px;
        text-transform: initial;
        width: 131px;
        font-family: 'SF Pro Display';  
        padding: 15px;
        margin-right:  0.5vw !important;
}
.recallButtonBA{
        font-weight: 700;
        font-size: 16px;
        border: 1px solid;
        border-radius: 4px;
        color: #DA2128;
        height: 37px;
        text-transform: initial;
        width: 131px;
        font-family: 'SF Pro Display';  
        padding: 15px;
        margin-right:  0.5vw !important;
}
.viewDetailsButtonBA{
        background: #003D4C;
        border-radius: 4px;
        color: #F9FCFF;
        font-weight: 700;
        font-size: 16px;
        height: 37px;
        text-transform: initial;
        width: 112px;
        font-family: 'SF Pro Display';
        padding: 15px;
        margin-right: 0.5vw !important;
}	
		.ironiconListing{
			color: #00839B !important;
			height:29px;
			width:29px;
		}
	.reqFromClass{
			padding-top:2.5%;
			padding-bottom:5px;
	}	
	oe-input, oe-combo, oe-date, oe-decimal {
		    --paper-input-container_-_border-radius: 4px !important;
	}
        .summary-details-container {
            padding: 16px 24px;
            min-height: fit-content;
            background: var(--primary-light-color) !important;
        }
        .top-preview-details {
            margin: 24px 0px;
        }
        .remarks-container {
            padding: 32px;
        }
        .bottom-buttons-container{
            padding:32px;
            margin: 3px 0px;
            column-gap: 16px;
        }
        .gap-tb-1 {
            margin: 8px 0px;
        }
        .gap-tb-2 {
            margin: 16px 0px;
        }
        .gap-tb-3 {
            margin: 24px 0px;
        }
        .gap-t-1 {
            margin-top: 8px;
        }
        .gap-t-2 {
            margin-top: 16px;
        }
        .gap-t-3 {
            margin-top: 24px;
        }
        .gap-b-1 {
            margin-bottom: 8px;
        }
        .gap-b-2 {
            margin-bottom: 16px;
        }
        .gap-b-3 {
            margin-bottom: 24px;
        }
        .margin-spacer {
            margin-top: 3px !important;
        }
        .margin-b-spacer {
            margin-bottom: 3px !important;
        }
        .column-gap {
            display:flex;
            column-gap: 16px;
        }

        .approval-selector-container approver-selector {
            --new-app-selector-width: auto;
        }
        .ironicon, .icon-color {
            color: #00839B !important;
        }

        paper-tabs.small {
            height: 55px;
        }

        paper-tabs.small .iron-selected .tab-text {
            line-height: 24px;
            letter-spacing: 0.15px;
            margin: 21px 0px 10px 0px;
            font-family: var(--bold-font) !important;
            color: var(--brand-color) !important;
            font-size: 15px;
        }
        paper-tabs.small .tab-text{
            line-height: 24px;
            letter-spacing: 0.15px;
            margin: 21px 0px 10px 0px;
            font-family: var(--regular-font) !important;
            font-style: normal;
            color: var(--secondary-color) !important;
            font-size: 15px;
        }

        .success-screen-container nbb-success-screen {
            --bannerInnerWidth: 100% !important;
            --transactionDetailsMainWidth: 100% !important; 
            --transactionDetailsMainMargin: 16px 0px !important;
        }

        .approval-status-container nbb-approval-status{
            --newWidth:100% !important;
            --current-user-details-div-width: 100% !important; 
            --current-user-details-width: auto !important; 
            --accordian-icon-padding: 0px !important;
        }

        .accordian-type-heading {
            background: var(--primary-light-color);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 3px;
            height: 58px;
            line-height: 55px;
        }

        .accordian-type-heading .heading iron-icon {
            width: 24px;
            height: 24px;
            padding-right: 16px;
        }
        .accordian-type-heading .expand-collapse {
            padding-right: 8px;
        }

        .expand-collapse iron-icon {
            width: var(--iron-icon-width, 29.29px);
            height: var(--iron-icon-height, 29.29px);
            color: var(--brand-color) !important;
            cursor: pointer;
        }

        .button-full-width {
            width: -webkit-fill-available !important;
        }
	.mbHeadingStyle{
		font-family: var(--bold-font) !important;
		font-size: var(--mb-heading-font-size, 24px);
		letter-spacing: 0.0025em;
	}
	.mbSecondaryColor{
	color: #636C75 !important;
	}
	.mbSecondaryHeadingStyle{
		font-family: var(--bold-font) !important;
		font-size: var(--mb-secondaryheading-font-size, 16px); 
		letter-spacing: 0.0025em;
	}
	.mbSecondarySubHeadingStyle{
		font-family: var(--bold-font) !important;
		font-size: var(--mb-secondary-sub-heading-font-size, 14px); 
		letter-spacing: 0.0025em;
	}
	.margin-t-2vh{
		    margin-top: 2vh;
	}
	.padding-5px{
		    padding: 5%;
	}
	.wordBreak-all{
		word-break: break-all;
	}
	.mbtopHeaderText{
		font-family: var(--bold-font) !important;
		font-size: var(--mb-top-header-font-size, 18px); 
		letter-spacing: 0.0025em;
	}
	.width10vw{
		width:10vw;
	}
	.width80vw{
		width:80vw;
	}
	.padding-2-p{
		padding:2%;
	}
	.padding-3-p{
		padding:3%;
	}
	.mbValueStyle{
		font-family: var(--bold-font) !important;
		font-size: var(--mb-secondaryheading-font-size, 16px); 
		letter-spacing: 0.0025em;
	}	 
	.mbInfoStyle{
		font-size: var(--mb-secondaryheading-font-size, 14px); 
		letter-spacing: 0.0025em;
		color: #636C75;
	}
	.margin-t-0.5vh{
		margin-top: 0.5vh;
	}
	.padding-b-1p{
		    padding-bottom: 1%;
	}
	.padding-l-r-1p{
		padding-left:1%;
		padding-right:1%;
	}
	
	
	.mb-details-font{
	   font-size: 15px;
	   padding-top: 0.5vh;
	   font-family: var(--bold-font) !important;
	}
	.mb-acct-det-fieldName{
		font-weight:500;
		font-size: 16px;
		letter-spacing: 0.15px;
		color: #636c75;
	}
	.mb-account-font{
	   color: #636C75 !important;
	   font-size: 15px;
	   font-family: 'SF-Pro-Display-Medium';
       font-weight: 700;
	   padding-top: 0.5vh;
	}
   	.padding-b-5p{
		    padding-bottom: 5%;
	}
	.margin-b-5p{
	   margin-top: 5%;
    }
	.mb-list-padding{
	   padding: 0% 2%;
	}
	.mb-margin-lr-7px{
	   margin-right: 7px !important;
	   margin-left: 7px !important;
	}
	.mb-margin-6px{
	   margin: 6px;
	}
	.commonMargin{
		    margin: 3% 3.5%;
	}
	.commonOECombo{
		--paper-input-container_-_height: 50px !important;
              --paper-input-container_-_min-height: 50px !important;
              background: #F4F5F7;
              --paper-input-container-label_-_padding-left: 15px !important;
              --paper-input-container-label_-_top: 0px !important;
              --new-padding:0px 10px;
              --errorclass-padding-top:1vh;
              --paper-input-container-input_-_top: 5px !important;
              --paper-input-container-input_-_left: 16px !important;
              --paper-input-container-input_-_line-height: 24px !important;
              --paper-input-container-label-floating_-_top: 15px !important;
              --paper-input-container-label-floating_-_font-size: 16px !important;
              --paper-input-container-label-floating_-_color: #636C75;
              --paper-input-container-label-floating_-_left: 4px !important;
              border-radius: 5px;
              --paper-input-container-underline-focus: {
                position: absolute;
                left: 0%;
                right: 0%;
                top: 5px;  /* For error msg */
                bottom: 0%;
                /*margin-top:22px!important;*/
                padding-top:0px !important;
                background: #00839B;
               }
	}
	.commonOEInput{
		  background: #F4F5F7;
		  border-radius: 5px;
		  --paper-input-container_-_height: 50px !important;
		  --paper-input-container_-_min-height: 50px !important;
		  --errorclass-padding-top:3vh;
		  --paper-input-container-input_-_top: 8px !important;
		  --paper-input-container-input_-_left: 16px !important;
		  --paper-input-container-input_-_line-height: 24px !important;
		  --paper-input-container-label-floating_-_top: 15px !important;
		  --paper-input-container-label_-_top: -5px;
		  --paper-input-container-label-floating_-_font-size: 16px !important;
		  --paper-input-container-label-floating_-_color: #636C75;
		  --paper-input-container-label-floating_-_left: 16px !important;
		  --oe-label-mixin_-_color: #636C75;
		  --paper-input-container-label_-_left: 16px !important;
		   --paper-input-container-underline-focus: {
			position: absolute;
			left: 0%;
			right: 0%;
			top: 5px;  /* For error msg */
			bottom: 0%; 
			padding-top:0px !important;
			background: #00839B;
               }
	}
	.commonOEComboNoFloatLabel{
		  background: #F4F5F7;
		  border-radius: 5px;
		  --paper-input-container_-_height: 50px !important;
		  --paper-input-container_-_min-height: 50px !important;
		  --errorclass-padding-top:3vh;
		  --paper-input-container-input_-_top: 0px !important;
		  --paper-input-container-input_-_left: 16px !important;
		  --paper-input-container-input_-_line-height: 24px !important;
		  --paper-input-container-label-floating_-_top: 15px !important;
		  --paper-input-container-label_-_top: -5px;
		  --paper-input-container-label-floating_-_font-size: 16px !important;
		  --paper-input-container-label-floating_-_color: #636C75;
		  --paper-input-container-label-floating_-_left: 16px !important;
		  --oe-label-mixin_-_color: #636C75;
		  --paper-input-container-label_-_left: 16px !important;
		   --paper-input-container-underline-focus: {
			position: absolute;
			left: 0%;
			right: 0%;
			top: 0px;  /* For error msg */
			bottom: 0%; 
			padding-top:0px !important;
			background: #00839B;
               }
	}
	.commonOEInputNoFloatLabel{
		  background: #F4F5F7;
		  border-radius: 5px;
		  --paper-input-container_-_height: 50px !important;
		  --paper-input-container_-_min-height: 50px !important;
		  --errorclass-padding-top:3vh;
		  --paper-input-container-input_-_top: 0px !important;
		  --paper-input-container-input_-_left: 16px !important;
		  --paper-input-container-input_-_line-height: 24px !important;
		  --paper-input-container-label-floating_-_top: 15px !important;
		  --paper-input-container-label_-_top: -5px;
		  --paper-input-container-label-floating_-_font-size: 16px !important;
		  --paper-input-container-label-floating_-_color: #636C75;
		  --paper-input-container-label-floating_-_left: 16px !important;
		  --oe-label-mixin_-_color: #636C75;
		  --paper-input-container-label_-_left: 16px !important;
		   --paper-input-container-underline-focus: {
			position: absolute;
			left: 0%;
			right: 0%;
			top: 0px;  /* For error msg */
			bottom: 0%; 
			padding-top:0px !important;
			background: #00839B;
               }
	}
  
  .table-action-icons {
            width: 20px;
            height: 20px;
        }

        .flex-wrap {
            flex-wrap: wrap;
        }

        .dom-repeat .column-value {
            overflow-wrap: anywhere;
            font-size: var(--a-font-size-s) !important;
        }
        .dom-repeat .column-heading {
            color: var(--secondary-color) !important;
            font-size: var(--a-font-size-xxm) !important;
            font-family: var(--bold-font) !important;
            text-transform: uppercase;
        }
        .field-label {
            color: var(--secondary-color) !important;
            font-size: var(--a-font-size-s) !important;
        }
        .text-transform-none{
            text-transform: none !important;
        }

        .filterCurrencyField {
            --paper-input-container-label_-_font-size: 13px !important;
            --paper-input-container_-_min-height: 40px!important;
            --paper-input-container_-_height: 40px!important;
            --iron-icon-newheight: 24px;
            --iron-icon-newwidth: 24px;
            --new-padding: 0px 10px 0px 0px;
            --errorclass-padding-top: 2px;
            background: #F4F5F7;
            --paper-input-container-input_-_top: 0px;
            border-radius: 4px;
            --paper-input-container-label-floating_-_left: 36px !important;
            --paper-input-container-label-floating_-_font-size: 16px !important;
            --paper-input-container-label-floating_-_top: 10px !important;
            --paper-input-container-label-floating_-_color: var(--secondary-color) !important;
            --paper-input-container-input_-_left: 0px !important;
            --paper-input-container-label_-_top: -5px !important;
            --paper-input-container-label_-_color: var(--secondary-color) !important;
        }
        
	
	
 </style>

        /*  <iron-media-query query="(max-width: 479px)" query-matches="{{phone}}"></iron-media-query>
        <iron-media-query query="(min-width: 480px) and (max-width: 839px)" query-matches="{{tablet}}"></iron-media-query>
        <iron-media-query query="(min-width: 840px)" query-matches="{{desktop}}"></iron-media-query> */
    </template>`;

AppStyles.register("app-styles");
