/**
©2018 EdgeVerve Systems Limited (a fully owned Infosys subsidiary),
Bangalore, India. All Rights Reserved.
**/
import './currency-formator-behaviour.js';

window.corpBehaviours = window.corpBehaviours || {};
corpBehaviours.appCommonBehaviourChild = {
    properties: {
        dateFormat: {
            type: String
        },
        userId: {
            type: String
        },
        corpId: {
            type: String
        },
        currencyLabel: {
            type: String,
            value: function () {
                return getLabel("l_Transferamount") + " " + clientStorage.get(storageType.session, 'homeCurrency')
            }
        },
        currencySortingOrder: {
            type: Array,
            value: ["BHD", "USD", "EUR", "GBP", "JPY", "SAR", "AED", "KWD", "OMR", "QAR"]
        },
        //to support multi lnaguages
        locale: String
    },
    ready: function () {
        this.dateFormat = clientStorage.get(storageType.session, 'dateFormat');
        this.locale = clientStorage.get(storageType.session, 'lang');
        var tempAmountFormat = clientStorage.get(storageType.session, 'amountFormat');
        if (tempAmountFormat == "Lakh Format") {
            this.amountFormat = "en-IN";
        } else {
            this.amountFormat = "en-US";
        }
        this.userId = clientStorage.get(storageType.session, 'userId');
        this.corpId = clientStorage.get(storageType.session, 'corpId');
    },
    formatCurrency: function (amt, sym) {
        if (!_.isNil(amt) && sym) {
            return this._symbolizeCurrency(sym + ' ' + amt);
        }
        return '';
    },
    formatAmountWithoutCurrency: function (amt) {
        if (!_.isNil(amt)) {
            this.homeCurrency = clientStorage.get(storageType.session, 'homeCurrency');
            return this._symbolizeCurrency(this.homeCurrency + ' ' + amt);
        }
        return '';
    },
    formatAddress: function (obj) {
        var str = '';
        if (obj) {
            str += !_.get(obj, 'address') ? '' : _.get(obj, 'address');
            str += (str ? ", " : "") + (!_.get(obj, 'addressLine1') ? '' : _.get(obj, 'addressLine1'));
            str += (str ? ", " : "") + (!_.get(obj, 'addressLine2') ? '' : _.get(obj, 'addressLine2'));
            str += (str ? ", " : "") + (!_.get(obj, 'city') ? '' : _.get(obj, 'city'));
            str += (str ? ", " : "") + (_.isString(obj.state) ? _.get(obj, 'state') :
                (!_.get(obj, 'state.stateDescription') ? '' : _.get(obj, 'state.stateDescription')));
            str += (str ? ", " : "") + (!_.get(obj, 'zipCode') ? '' : _.get(obj, 'zipCode'));
            str += (str ? ", " : "") + (_.isString(obj.country) ? _.get(obj, 'country') :
                (!_.get(obj, 'country.codeDescription') ? '' : _.get(obj, 'country.codeDescription')));
        }
        return str;
    },
    maskedAccount: function (accountId, codeType) {
        return accountId ? maskAccount.accountFormat(accountId, codeType) : '';
    },
    formatDate: function (date) {
        if (_.isEmpty(date)) return "";
        else {
            var res = moment.utc(date);
            res = res.format(clientStorage.get(storageType.session, 'dateFormat'));
            //res = moment(res.toDate()).format(clientStorage.get(storageType.session, 'dateFormat'));
            return res;
        }

    },
    displayLiteral: function (lit) {
        return getLabel(lit);
    },
    checkEquality: function (value1, value2) {
        if (value1 == value2)
            return true;
        else
            return false;
    },
    logicalAnd: function (value1, value2) {
        if (value1 && value2)
            return true;
        return false;
    },
    //added to handle timestamp issue for dates displayed in UI components as a part of 326179 fix
    formatTemplateDate: function (date) {
        var formatedDate = setBusinessToDateString(date);
        if (!this.dateFormat)
            this.dateFormat = clientStorage.get(storageType.session, 'dateFormat');
        return OEUtils.DateUtils.format(formatedDate, this.dateFormat);
    },
    formatYesNoValues: function (value) {
        if (value == "Y" || value == "N") {
            if (value == "Y") {
                return getLabel('b_yestxt');
            }
            else return getLabel('b_notxt');
        }
    },
    _showInlineError: function (elementRef, errorMessage, labelFlag) {
        var element = elementRef;
        var label = "";
        if (labelFlag) {
            var labelLiteral = element.__data.label;
            label = (labelLiteral != undefined) ? getLabel(labelLiteral) : "";
        }
        element.invalid = true;
        //element.errorMessage = errorMessage;
        element.errorMessage = (errorMessage + label);
        elementRef.scrollIntoView();
    },

    _isEqualTo: function (val1, val2) {
        return val1 === val2;
    },

    downloadFileBeh(e, fileSeqNo) {
        var fileSeqNumber = fileSeqNo;
        var self = this;
        self.fire('start-processing-loader');
        var ajaxCall = document.createElement('oe-ajax');
        ajaxCall.contentType = "application/json";
        ajaxCall.handleAs = "json";
        ajaxCall.url = "/api/CBUsers/" + self.userId + "/download-file/" + fileSeqNumber;
        ajaxCall.method = 'get';
        ajaxCall.addEventListener('response', function (event) {
            self.fileResponse = event.detail.response;

            self._gotDownloadtText();
        });
        ajaxCall.addEventListener("error", function (event) {
            self.fire('stop-processing-loader');

            if (event.detail.request.xhr.getResponseHeader('channelcontext')) {
                var tempHeader = JSON.parse(event.detail.request.xhr.getResponseHeader('channelcontext'));
                self.fire('oe-show-error', tempHeader.status.message[0].messageDesc);
            } else self.fire('oe-show-error', event.detail.error);
        });
        ajaxCall.generateRequest();
    },
    fileAttachmentBeh(e, fileSeqNo) {
        var fileSeqNumber = fileSeqNo;
        var self = this;
        self.fire('start-processing-loader');
        var ajaxCall = document.createElement('oe-ajax');
        ajaxCall.contentType = "application/json";
        ajaxCall.handleAs = "json";
        ajaxCall.url = "/api/CBUsers/" + self.userId + "/fileattachment/" + fileSeqNumber;
        ajaxCall.method = 'get';
        ajaxCall.addEventListener('response', function (event) {
            self.fileResponse = event.detail.response;

            self._gotDownloadtText();
        });
        ajaxCall.addEventListener("error", function (event) {
            self.fire('stop-processing-loader');

            if (event.detail.request.xhr.getResponseHeader('channelcontext')) {
                var tempHeader = JSON.parse(event.detail.request.xhr.getResponseHeader('channelcontext'));
                self.fire('oe-show-error', tempHeader.status.message[0].messageDesc);
            } else self.fire('oe-show-error', event.detail.error);
        });
        ajaxCall.generateRequest();
    },
    _gotDownloadtText(e) {
        //this.set('fileResponse', e.detail.response);
        this.downloadData = "data:application/octet-stream;base64," + this.fileResponse.fileData;
        if (this.fileResponse.fileName == undefined) this.fileName = this.fileName + "." + this.downloadfileType.toLowerCase(); else this.fileName = this.fileResponse.fileName;
        var data = this.fileResponse.fileData; //msSaveOrOpenBlob is used to save file in IE 

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //decode base-64 encoded string
            var byteCharacters = atob(data); //creating binary data

            var byteNumbers = new Array(byteCharacters.length);

            for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
                type: 'data:application/octet-stream;base64,'
            }); //save or open blob file

            window.navigator.msSaveOrOpenBlob(blob, this.fileName);
        } else {
            //this.set('fileResponse', e.detail.response);
            this.downloadData = "data:application/octet-stream;base64," + this.fileResponse.fileData;
            var downloadLink = document.createElement('a');
            downloadLink.download = this.$$("#app_download_link").download;
            downloadLink.href = this.downloadData;
            downloadLink.click();
        }

        this.fire('stop-processing-loader');
    },
    _isShowTable: function (items) {
        return items && items.length > 0 ? true : false;
    },
    _statusBackGroundColor(code) {
        var greenStatusCodes = ['SUC', 'ACT'];
        var blueStatusCodes = [, 'REC'];
        var redStatusCodes = ['REJ', 'CAN'];
        var yellowStatusCodes = ['PCA', 'PMA', 'ENT', 'PEN', 'WFR'];
        if (greenStatusCodes.includes(code)) return 'greenStatus';
        else if (blueStatusCodes.includes(code)) return 'blueStatus';
        else if (redStatusCodes.includes(code)) return 'redStatus';
        else if (yellowStatusCodes.includes(code)) return 'yellowStatus';
    },
    _SRTabnavigateBack() {
        var tab;
        var selectedIndex;
        selectedIndex = this.parentNode.getRootNode().host.parentNode.getRootNode().getElementById('corporate_service_landing_tab_iron_pages').selected;
        tab = this.parentNode.getRootNode().host.parentNode.getRootNode().getElementById('corporate_service_landing_tab_section_' + selectedIndex);
        tab.querySelector("iron-icon").click();
    },
    _onlyFirstLetterUpper: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    _isEmpty: function (str) {
        return str == '' || str == undefined || str == null;
    },
    getStatusClass(cmCode) {
        var statusArr = [
            { cmCode: 'SUC', class: 'success' },
            { cmCode: 'FAL', class: 'rejected' },
            { cmCode: 'SUS', class: 'pending-approval' },
            { cmCode: 'PSU', class: 'pending-approval' },
            { cmCode: 'PFP', class: 'processing' },
            { cmCode: 'REJ', class: 'processing' },
            { cmCode: 'PMA', class: 'processing' },
            { cmCode: 'PCA', class: 'processing' },
            { cmCode: 'PBA', class: 'processing' },
            { cmCode: 'REC', class: 'rejected' },
            { cmCode: 'REP', class: 'processing' },
            { cmCode: 'PRO', class: 'pending-approval' },
            { cmCode: 'CAN', class: 'processing' },
            { cmCode: 'PEN', class: 'processing' },
            { cmCode: 'SFP', class: 'processing' },
            { cmCode: 'EXP', class: 'processing' },
            { cmCode: 'FTD', class: 'processing' },
            { cmCode: 'ACT', class: 'processing' },
            { cmCode: 'SAV', class: 'processing' },
            { cmCode: 'PFR', class: 'processing' },
            { cmCode: 'PRD', class: 'success' },
            { cmCode: '001', class: 'active' },
            { cmCode: '012', class: 'inactive' },
            { cmCode: '002', class: 'rejected' },
            { cmCode: 'SUC', class: 'success' },
            { cmCode: 'ACT', class: 'success' },
            { cmCode: 'APR', class: 'success' },
            { cmCode: 'UPL', class: 'success' },
            { cmCode: 'ENT', class: 'blueStatus' },
            { cmCode: 'REC', class: 'rejected' },
            { cmCode: 'DSP', class: 'blueStatus' },
            { cmCode: 'REJ', class: 'inactive' },
            { cmCode: 'HLD', class: 'pending-approval' },
            { cmCode: 'My Approval', class: 'processing' }
        ];

        var findedStatus = statusArr.find(item => item.cmCode === cmCode);
        var statusClass = (findedStatus && findedStatus.class) ? findedStatus.class : 'processing';
        return statusClass;
    },
    _setFiltersFromFilterObj(originalUrl, data) {
        var newUrl = originalUrl;
        if (originalUrl && !_.isEmpty(data)) {
            for (var d in data) {
                if (!_.isEmpty(data[d])) {
                    if (newUrl && newUrl.indexOf("?") !== -1) {
                        newUrl += "&filter[where][" + d + "]=" + data[d];
                    } else {
                        newUrl += "?filter[where][" + d + "]=" + data[d];
                    }
                }
            }
            return newUrl;
        }
    },
    _getCounterPartyAccountNumber(obj) {
        if (obj && obj.hasOwnProperty('counterpartyType') && obj.counterpartyType === 'H') {
            return _.get(obj, 'payeeAccountDetails.adhocPayeeDetails.accountNumber') || '';
        } else {
            return _.get(obj, 'payeeAccountDetails.personalPayees.accountNumber') || _.get(obj, 'payeeAccountDetails.counterpartyAccount') || '';
        }
    },
    _getCounterPartyAccountName(obj) {
        if (obj && obj.hasOwnProperty('counterpartyType') && obj.counterpartyType === 'H') {
            return _.get(obj, 'payeeAccountDetails.adhocPayeeDetails.payeeName') || '';
        } else {
            return _.get(obj, 'payeeAccountDetails.accounts.accountNickname') || _.get(obj, 'payeeAccountDetails.personalPayees.payeeNickname') || _.get(obj, 'payeeAccountDetails.billPresentmentDetails.billerNickname') || _.get(obj, 'payeeAccountDetails.paymentBillers.billerNickname') || '';
        }
    },
    _calTotalAmount: function (arr, key) {
        const sum = arr.reduce((accumulator, object) => {
            if (object[key] && object[key].amount) {
                return accumulator + object[key].amount;
            }
        }, 0);
        return sum;
    },
    _getSuccessMessage: function (chennelContext, multiple = false) {
        if (chennelContext) {
            var message = chennelContext.status.message;
            if (multiple) {
                if (message && message.length) {
                    var subTitleList = message.map(item => {
                        if (item.hasOwnProperty("messageDesc")) {
                            return item.messageDesc;
                        }
                    });
                    return subTitleList;
                }
            } else {
                return message[0].messageDesc;
            }
        }
    },
    isNonEmptyObject(value) {
        return value && value !== null && value !== undefined && value.constructor === Object && Object.keys(value).length > 0;
    },
    isNonEmptyArray(value) {
        return value && value !== null && value !== undefined && Array.isArray(value) && value.length > 0;
    },
    isManualReq(isManual) {
        if (isManual == "Y")
            return "Required";
        else
            return "Not required";
    },
    printPage() {
        window.print();
    },
    _commonHandleResponseError: function (event) {
        this.fire('stop-processing-loader');
        if (event.detail.request.xhr.getResponseHeader('channelcontext')) {
            var tempHeader = JSON.parse(event.detail.request.xhr.getResponseHeader('channelcontext'));
            var errorMessage = tempHeader.status.message[0].messageDesc || event.detail.error.message;
            this.fire('oe-show-error', errorMessage);
        }
    },
    getModuleName(item) {
        var list = item.split('|');
        var moduleName = ''
        var newArr = list.map(function (value) {
            var list1 = value.split('=');
            var obj = {
                id: list1[0],
                value: list1[1]
            }
            return obj;
        });
        for (var i in newArr) {
            if (newArr[i].id == 'REQUEST_TYPE') {
                moduleName = newArr[i].value;
            }
        }
        return moduleName


    },
    stopProcessingLoader(event) {
        this.fire('stop-processing-loader');
        if (event.detail.request.xhr.getResponseHeader('channelcontext')) {
            var tempHeader = JSON.parse(event.detail.request.xhr.getResponseHeader('channelcontext'));
            this.fire('oe-show-error', tempHeader.status.message[0].messageDesc);
        }
        else this.fire('oe-show-error', event.detail.error);
    },
    _getBeneficiaryName(itemObj) {
        return itemObj.numberOfEntries > 1 ? "Multiple (" + itemObj.numberOfEntries + ")" : itemObj.accountNickname;
    },
    _getRequestReference(data) {
        return _.get(data, 'commonSection.requestReferenceId') || _.get(data, 'requestReferenceId') || '';
    },
    currencySeparater(currencyStr) {
        if (typeof (currencyStr) == "object") {
            var curr = currencyStr.currency;
            var amt = this._formatCurrencywithPrecision(curr, currencyStr.amount);
            var Value = curr + " " + amt;
            return Value
        }
        else {
            var curr = currencyStr.split("|")[0];
            var Amt = currencyStr.split("|")[1];
            var Value = curr + " " + this._formatCurrencywithPrecision(curr, Amt);
            return Value
        }


    },

    _maskCreditCard(card) {
        if (card.length == 16) {
            var strOne = card.substring(0, 4);
            var strTwo = card.substring(4, 6);
            var strThree = card.substring(12);
            return strOne + ' ' + strTwo + '** **** ' + strThree;
        }
        return card;
    },
    _callSupportingAPI() {
        if (this.fileAttachmentValue != undefined && this.fileAttachmentValue != null && this.fileAttachmentValue != "") {
            var self = this;
            self.fire('start-processing-loader');
            var fileData = "";
            fileData = self.fileAttachmentValue.split(',')[1].trim();
            var ajaxCall;
            ajaxCall = document.createElement('oe-ajax');
            ajaxCall.contentType = 'application/json';
            ajaxCall.handleAs = "json";
            ajaxCall.url = "/api/CBUsers/" + clientStorage.get(storageType.session, 'userId') + "/fileattachment";
            ajaxCall.method = 'post';
            ajaxCall.body = [{
                "fileName": self.fileAttachmentName,
                "fileData": fileData
            }];
            ajaxCall.addEventListener('response', function (e) {
                self.fire('stop-processing-loader');
                self.showDownload = true;
                self.fileUploadSeqNo = "";
                self.fileUploadSeqNo = e.detail.response[0].fileSeqNo;
                self.fileUploadName = "";
                self.fileUploadName = e.detail.response[0].fileName;
            });
            ajaxCall.addEventListener('error', function (event) {
                self.fire('stop-processing-loader');
                self.fileUploadSeqNo = "";
                self.fileUploadName = "";

                if (event.detail.request.xhr.getResponseHeader('channelcontext')) {
                    var tempHeader = JSON.parse(event.detail.request.xhr.getResponseHeader('channelcontext'));
                    self.fire('oe-show-error', tempHeader.status.message[0].messageDesc);
                } else self.fire('oe-show-error', event.detail.error);
            });
            oeAjaxEventListner(ajaxCall);
            ajaxCall.generateRequest();
        }
    },

    _convertDateToRange(fromDate, toDate) {
        if (fromDate && fromDate != undefined && toDate && toDate != undefined) {
            var searchDateRange = {
                "lt": moment(fromDate).format("YYYY-MM-DDT00:00:00"),
                "gt": moment(toDate).format("YYYY-MM-DDT23:59:59")

            };
        }
        return searchDateRange;
    },

    checkTenentIdNBB() {
        if (sessionStorage.getItem("tenantId") && sessionStorage.getItem("tenantId").toUpperCase() === 'NBB') return true;
        else return false
    },

    checkTenentIdNBBUAE() {
        if (sessionStorage.getItem("tenantId") && sessionStorage.getItem("tenantId").toUpperCase() === 'NBBUAE') return true;
        else return false
    },
    validateFileExtention(fileExtention, validList) {
        //var validList = ['pdf', 'jpg', 'jpeg'];
        var flag = false;
        if (fileExtention && validList) {
            flag = (validList.findIndex(ext => ext.toLowerCase() === fileExtention.toLowerCase()) !== -1)
        }
        //flag = validList.includes(fileExtention);
        return flag;

    },
    nonwpsFileUploadUrl(prodType, urlType) {
        var url;
        if (urlType == 'initiation') {
            if (prodType == 'NWS') url = "/api/CBUsers/" + this.usersId + "/nonwpssalaryupload";
            else if (prodType == 'GPS') url = "/api/CBUsers/" + this.usersId + "/gpssasalaryupload";
            else if (prodType == 'ADS') url = "/api/CBUsers/" + this.usersId + "/abudhabiupload";
        }
        else if (urlType == 'approval') {
            if (prodType == 'NWS') url = "/api/CBUsers/" + this.usersId + "/nonwpssalaryupload-approval";
            else if (prodType == 'GPS') url = "/api/CBUsers/" + this.usersId + "/gpssasalaryupload";
            else if (prodType == 'ADS') url = "/api/CBUsers/" + this.usersId + "/abudhabiupload-approval";

        }
        else if (urlType == 'approvallist') {
            if (prodType == 'NWS') url = "/api/CBUsers/" + this.usersId + "/nonwpssalaryupload-approvallist";
            else if (prodType == 'GPS') url = "/api/CBUsers/" + this.usersId + "/gpssasalaryupload";
            else if (prodType == 'ADS') url = "/api/CBUsers/" + this.usersId + "/abudhabiupload-approvallist";

        }
        else if (urlType == 'approve') {
            if (prodType == 'NWS') url = "/api/CBUsers/" + this.usersId + "/nonwpssalaryupload-approve-requests";
            else if (prodType == 'GPS') url = "/api/CBUsers/" + this.usersId + "/gpssasalaryupload-approve-requests";
            else if (prodType == 'ADS') url = "/api/CBUsers/" + this.usersId + "/abudhabiupload-approve-requests";

        }
        else if (urlType == 'reject') {
            if (prodType == 'NWS') url = "/api/CBUsers/" + this.usersId + "/nonwpssalaryupload-reject-requests";
            else if (prodType == 'GPS') url = "/api/CBUsers/" + this.usersId + "/gpssasalaryupload-reject-requests";
            else if (prodType == 'ADS') url = "/api/CBUsers/" + this.usersId + "/abudhabiupload-reject-requests";

        }


        if (url) return url;
    }



};
corpBehaviours.appCommonBehaviour = [corpBehaviours.appCommonBehaviourChild, sbBehaviours.currencyFormatorBehaviour];